/** @format */
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Unpool from '../Icons/UnPool';
import DeleteOutlined from '../assignments/DeleteOutlined';
import ThreeDotEllipses from '../Icons/ThreeDotEllipses';
import GearOutlined from '../Icons/GearOutlined';

const ItemMenu = (props) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (e) => {
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
	};
	const handleClose = (e) => {
		e.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<div className='item-menu'>
			<IconButton
				onClick={handleClick}
				sx={{ ml: 2 }}
				aria-controls={open ? 'account-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
			>
				<ThreeDotEllipses />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem onClick={() => props.removeItems(props.currentNode)}>
					<ListItemIcon>
						<DeleteOutlined fontSize='small' />
					</ListItemIcon>
					<ListItemText>Remove</ListItemText>
				</MenuItem>
				{props.currentNode.isLastChild !== undefined && (
					<MenuItem onClick={() => props.unPool(props.currentNode)}>
						<ListItemIcon>
							<Unpool />
						</ListItemIcon>
						<ListItemText>Unpool</ListItemText>
					</MenuItem>
				)}
				<MenuItem onClick={handleClose}>
					<ListItemIcon>
						<GearOutlined />
					</ListItemIcon>
					<ListItemText>Item settings</ListItemText>
				</MenuItem>
			</Menu>
		</div>
	);
};
export default ItemMenu;
