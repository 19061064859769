/** @format */

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CheckIcon() {
	return (
		<SvgIcon>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
			>
				<path
					d='M10.7096 15.78C10.417 16.0733 9.94251 16.0733 9.64986 15.78C9.64812 15.7783 7.21948 13.3434 7.21948 13.3434C6.92684 13.05 6.92684 12.5743 7.21948 12.281C7.51213 11.9876 7.9866 11.9876 8.27925 12.281L10.1432 14.1497L15.2867 8.25656C15.5592 7.94431 16.0326 7.91267 16.3441 8.18589C16.6556 8.45911 16.6871 8.93374 16.4146 9.24599L10.7485 15.7379C10.7362 15.7523 10.7232 15.7663 10.7096 15.78Z'
					fill='#020917'
				/>
			</svg>
		</SvgIcon>
	);
}
