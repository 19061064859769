import { createSvgIcon } from '@mui/material';

const Difficulty4 = createSvgIcon(
    <>
        <g clip-path="url(#clip0_16356_366259)">
            <circle cx="4" cy="4" r="4" fill="#00829D"/>
            <circle cx="16" cy="4" r="4" fill="#00829D"/>
            <circle cx="28" cy="4" r="4" fill="#00829D"/>
            <circle cx="40" cy="4" r="4" fill="#00829D"/>
        </g>
        <defs>
            <clipPath id="clip0_16356_366259">
                <rect width="44" height="8" fill="white"/>
            </clipPath>
        </defs>
    </>
    ,
  'Difficulty4'
);

export default Difficulty4;
