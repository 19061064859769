/** @format */

// State held component not using Redux
import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import ChapterSection from './ChapterSection';
import { Typography } from '@mui/material';

const FeaturedContentDetails = (props) => {
	const [data, setData] = useState(
		props.assignmentUnits.filter((a) => a.chapter === props.selectedChapter)
	);

	useEffect(() => {
		var tempData = [];
		if (props.standardsView) {
			if (props.selectedObjective !== 'all') {
				tempData = data.filter((a) => a.objective === props.selectedObjective);
				tempData.map((item) => {
					item.sectionOpen = true;
					return item;
				});
			} else {
				tempData = [...data];
			}
		} else {
			if (props.selectedSection !== 'all') {
				tempData = data.filter((a) => a.section === props.selectedSection);
				tempData.map((item) => {
					item.sectionOpen = true;
					return item;
				});
			} else {
				tempData = [...data];
			}
		}
		setData(tempData);
	}, [
		props.selectedChapter,
		props.selectedSection,
		props.selectedObjective,
		props.standardsView,
	]);

	return props.standardsView ? (
		<div className='section-list'>
			{props.selectedObjective === 'all' ? (
				data.length === 0 ? (
					<Typography
						variant='subtitle3'
						sx={{ my: 2 }}
					>
						The prototype does not include data for the chapter you selected.
						Please select a different chapter to continue.
					</Typography>
				) : (
					<>
						{props.objectives.map((objective, i) => {
							return (
								<ChapterSection
									data={data.filter((a) => a.objective === objective)}
									key={uuid()}
									updateSelections={props.updateSelections}
									toggleSection={props.toggleSection}
									previewOpen={props.previewOpen}
									group={objective}
									standardsView={props.standardsView}
									sectionIndex={i}
								/>
							);
						})}
					</>
				)
			) : (
				<ChapterSection
					data={data.filter((a) => a.objective === props.selectedObjective)}
					group={props.selectedObjective}
					key={uuid()}
					updateSelections={props.updateSelections}
					toggleSection={props.toggleSection}
					previewOpen={props.previewOpen}
					standardsView={props.standardsView}
					sectionIndex={0}
				/>
			)}
		</div>
	) : (
		<div className='section-list'>
			{props.selectedSection === 'all' ? (
				data.length === 0 ? (
					<Typography
						variant='body1'
						sx={{ my: 2, textAlign: 'center' }}
					>
						The prototype does not include data for the chapter you selected.
						Please select a different chapter to continue.
					</Typography>
				) : (
					<ul key={uuid()}>
						{props.sections.map((section, i) => {
							return (
								<ChapterSection
									data={data.filter((a) => a.section === section)}
									key={uuid()}
									updateSelections={props.updateSelections}
									toggleSection={props.toggleSection}
									previewOpen={props.previewOpen}
									togglePreview={props.togglePreview}
									group={section}
									standardsView={props.standardsView}
									duplicateAlert={props.duplicateAlert}
								/>
							);
						})}
					</ul>
				)
			) : (
				<ul key={uuid()}>
					<ChapterSection
						data={data.filter((a) => a.section === props.selectedSection)}
						group={props.selectedSection}
						key={uuid()}
						updateSelections={props.updateSelections}
						toggleSection={props.toggleSection}
						previewOpen={props.previewOpen}
						standardsView={props.standardsView}
						duplicateAlert={props.duplicateAlert}
					/>
				</ul>
			)}
		</div>
	);
};

export default FeaturedContentDetails;
