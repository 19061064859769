import { createSvgIcon } from '@mui/material';

const ThreeDotEllipses = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11.75 16.8C12.7165 16.8 13.5 17.5835 13.5 18.55C13.5 19.5165 12.7165 20.3 11.75 20.3C10.7835 20.3 10 19.5165 10 18.55C10 17.5835 10.7835 16.8 11.75 16.8ZM11.75 10.4C12.7165 10.4 13.5 11.1835 13.5 12.15C13.5 13.1165 12.7165 13.9 11.75 13.9C10.7835 13.9 10 13.1165 10 12.15C10 11.1835 10.7835 10.4 11.75 10.4ZM11.75 4C12.7165 4 13.5 4.7835 13.5 5.75C13.5 6.7165 12.7165 7.5 11.75 7.5C10.7835 7.5 10 6.7165 10 5.75C10 4.7835 10.7835 4 11.75 4Z"
    fill="currentColor"
  />,
  'ThreeDotEllipses'
);

export default ThreeDotEllipses;
