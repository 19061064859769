/** @format */
import { Link, MenuList, MenuItem } from '@mui/material';

const MainNav = (props) => {
	return (
		<MenuList className='main-menu'>
			<MenuItem selected={props.activeNav === 'home' || props.activeNav === ''}>
				<Link href='/prototypes/modern-xl-instructor/'>Home</Link>
			</MenuItem>
			<MenuItem selected={props.activeNav === 'assignments'}>
				<Link href='/prototypes/modern-xl-instructor/assignments'>
					Assignments
				</Link>
			</MenuItem>
			<MenuItem>
				<Link href='/prototypes/modern-xl-instructor/eTextbook'>eTextbook</Link>
			</MenuItem>
			<MenuItem>
				<Link href='/prototypes/modern-xl-instructor/blank'>Menu 4</Link>
			</MenuItem>
			<MenuItem>
				<Link href='/prototypes/modern-xl-instructor/blank'>Menu 5</Link>
			</MenuItem>
		</MenuList>
	);
};

export default MainNav;
