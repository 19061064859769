/** @format */

import pageMediaLibrary from '../assets/images/page_media_library.png';

export default function MediaLibrary(props) {
	return (
		<div className={`old-page ${props.mode}`}>
			<h2 className='page-title'>Media Library</h2>
			<img
				src={pageMediaLibrary}
				alt=''
				style={{ display: 'block', margin: '0 auto' }}
			/>
		</div>
	);
}
