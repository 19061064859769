// State held component not using Redux
import React, { Component } from 'react';

class Switch extends Component {
  constructor(props) {
    super(props);
    this.state = {
        checked: this.props.checked,
        disabled: this.props.disabled
    };

    this.onChangeHandle = this.onChangeHandle.bind(this);
  }

  onChangeHandle() {
      this.setState({
         checked: !this.state.checked
      });
  }

  render () {
    return (
        <label className="switch">
            <input type="checkbox" checked={this.state.checked} onChange={this.onChangeHandle} disabled={this.state.disabled} />
            <span className="slider"></span>
        </label>
    )
  }
}

export default Switch;
