/**
 * Do not edit directly
 * Generated on Mon, 10 Jun 2024 19:20:17 GMT
 */

export const TOKEN_COLOR_THEME_WHITE = "#ffffffff";
export const TOKEN_COLOR_THEME_BLACK = "#000000ff";
export const TOKEN_COLOR_THEME_INDIGO = "#1d3557ff";
export const TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT = "#020917ff";
export const TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_MUTED = "#585858ff";
export const TOKEN_COLOR_THEME_TEXT_DARKTHEME_DEFAULT = "#fefefeff";
export const TOKEN_COLOR_THEME_TEXT_DARKTHEME_MUTED = "#b5b5b5ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_PRIMARY = "#007e53ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_PRIMARY = "#ffffffff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_PRIMARY_CONTAINER = "#5bdda0ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_PRIMARY_CONTAINER = "#005234ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_PRIMARY_ON_SURFACE = "#006c46ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SECONDARY = "#5066c5ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_SECONDARY = "#ffffffff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SECONDARY_CONTAINER = "#b9c3ffff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_SECONDARY_CONTAINER = "#293e98ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SECONDARY_ON_SURFACE = "#4357b2ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_TERTIARY = "#00809bff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_TERTIARY = "#ffffffff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_TERTIARY_CONTAINER = "#5ad5f9ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_TERTIARY_CONTAINER = "#004e5fff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_TERTIARY_ON_SURFACE = "#00677dff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ERROR = "#af2e30ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_ERROR = "#ffffffff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ERROR_CONTAINER = "#ffb3aeff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_ERROR_CONTAINER = "#8d131bff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ERROR_ON_SURFACE = "#af2e30ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SUCCESS = "#008859ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_SUCCESS = "#ffffffff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SUCCESS_CONTAINER = "#5bdda0ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_SUCCESS_CONTAINER = "#005234ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SUCCESS_ON_SURFACE = "#006c46ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_WARNING = "#857100ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_WARNING = "#ffffffff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_WARNING_CONTAINER = "#fee264ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_WARNING_CONTAINER = "#534600ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_WARNING_ON_SURFACE = "#6e5d00ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SAFETY = "#d56818ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_SAFETY = "#ffffffff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SAFETY_CONTAINER = "#ffaf77ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_SAFETY_CONTAINER = "#773a0eff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SAFETY_ON_SURFACE = "#a65113ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_INFO = "#00809bff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_INFO = "#ffffffff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_INFO_CONTAINER = "#5ad5f9ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_INFO_CONTAINER = "#004e5fff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_INFO_ON_SURFACE = "#00677dff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_OUTLINE = "#757874ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_BACKGROUND = "#ffffffff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_BACKGROUND = "#191c1aff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SURFACE = "#eeeeedff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_SURFACE = "#191c1aff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SURFACE_VARIANT = "#e1e3dfff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_ON_SURFACE_VARIANT = "#444845ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_SURFACE = "#2e312eff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_ON_SURFACE = "#eff1edff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_PRIMARY = "#5bdda0ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_SECONDARY = "#b9c3ffff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_TERTIARY = "#5ad5f9ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SHADOW = "#000000ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SURFACE_TINT = "#006c46ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_OUTLINE_VARIANT = "#c0c9c1ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_SCRIM = "#000000ff";
export const TOKEN_COLOR_THEME_SYS_LIGHT_FOCUS_A11Y = "#266eb5ff";
export const TOKEN_COLOR_THEME_SYS_DARK_PRIMARY = "#5bdda0ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_PRIMARY = "#003822ff";
export const TOKEN_COLOR_THEME_SYS_DARK_PRIMARY_CONTAINER = "#006c46ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_PRIMARY_CONTAINER = "#7afabaff";
export const TOKEN_COLOR_THEME_SYS_DARK_PRIMARY_ON_SURFACE = "#3ac186ff";
export const TOKEN_COLOR_THEME_SYS_DARK_SECONDARY = "#94a6ffff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_SECONDARY = "#092582ff";
export const TOKEN_COLOR_THEME_SYS_DARK_SECONDARY_CONTAINER = "#4357b2ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_SECONDARY_CONTAINER = "#dde1ffff";
export const TOKEN_COLOR_THEME_SYS_DARK_SECONDARY_ON_SURFACE = "#94a6ffff";
export const TOKEN_COLOR_THEME_SYS_DARK_TERTIARY = "#5ad5f9ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_TERTIARY = "#003642ff";
export const TOKEN_COLOR_THEME_SYS_DARK_TERTIARY_CONTAINER = "#00677dff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_TERTIARY_CONTAINER = "#b3ebffff";
export const TOKEN_COLOR_THEME_SYS_DARK_TERTIARY_ON_SURFACE = "#34b9dcff";
export const TOKEN_COLOR_THEME_SYS_DARK_ERROR = "#ffb3aeff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_ERROR = "#68000cff";
export const TOKEN_COLOR_THEME_SYS_DARK_ERROR_CONTAINER = "#8d131bff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_ERROR_CONTAINER = "#ffdad7ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ERROR_ON_SURFACE = "#ff8983ff";
export const TOKEN_COLOR_THEME_SYS_DARK_SUCCESS = "#5bdda0ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_SUCCESS = "#003822ff";
export const TOKEN_COLOR_THEME_SYS_DARK_SUCCESS_CONTAINER = "#006c46ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_SUCCESS_CONTAINER = "#7afabaff";
export const TOKEN_COLOR_THEME_SYS_DARK_SUCCESS_ON_SURFACE = "#3ac186ff";
export const TOKEN_COLOR_THEME_SYS_DARK_WARNING = "#e1c64bff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_WARNING = "#3a3000ff";
export const TOKEN_COLOR_THEME_SYS_DARK_WARNING_CONTAINER = "#6e5d00ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_WARNING_CONTAINER = "#fee264ff";
export const TOKEN_COLOR_THEME_SYS_DARK_WARNING_ON_SURFACE = "#c4aa32ff";
export const TOKEN_COLOR_THEME_SYS_DARK_SAFETY = "#ffaf77ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_SAFETY = "#4c2a12ff";
export const TOKEN_COLOR_THEME_SYS_DARK_SAFETY_CONTAINER = "#773a0eff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_SAFETY_CONTAINER = "#ffc69eff";
export const TOKEN_COLOR_THEME_SYS_DARK_SAFETY_ON_SURFACE = "#ff9850ff";
export const TOKEN_COLOR_THEME_SYS_DARK_INFO = "#5ad5f9ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_INFO = "#003642ff";
export const TOKEN_COLOR_THEME_SYS_DARK_INFO_CONTAINER = "#00677dff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_INFO_CONTAINER = "#b3ebffff";
export const TOKEN_COLOR_THEME_SYS_DARK_INFO_ON_SURFACE = "#34b9dcff";
export const TOKEN_COLOR_THEME_SYS_DARK_OUTLINE = "#8f918eff";
export const TOKEN_COLOR_THEME_SYS_DARK_BACKGROUND = "#000000ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_BACKGROUND = "#e1e3dfff";
export const TOKEN_COLOR_THEME_SYS_DARK_SURFACE = "#191c1aff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_SURFACE = "#c5c7c3ff";
export const TOKEN_COLOR_THEME_SYS_DARK_SURFACE_VARIANT = "#444845ff";
export const TOKEN_COLOR_THEME_SYS_DARK_ON_SURFACE_VARIANT = "#c5c7c3ff";
export const TOKEN_COLOR_THEME_SYS_DARK_INVERSE_SURFACE = "#e1e3dfff";
export const TOKEN_COLOR_THEME_SYS_DARK_INVERSE_ON_SURFACE = "#191c1aff";
export const TOKEN_COLOR_THEME_SYS_DARK_INVERSE_PRIMARY = "#006c46ff";
export const TOKEN_COLOR_THEME_SYS_DARK_INVERSE_SECONDARY = "#4357b2ff";
export const TOKEN_COLOR_THEME_SYS_DARK_INVERSE_TERTIARY = "#00677dff";
export const TOKEN_COLOR_THEME_SYS_DARK_SHADOW = "#000000ff";
export const TOKEN_COLOR_THEME_SYS_DARK_SURFACE_TINT = "#5bdda0ff";
export const TOKEN_COLOR_THEME_SYS_DARK_OUTLINE_VARIANT = "#404943ff";
export const TOKEN_COLOR_THEME_SYS_DARK_SCRIM = "#000000ff";
export const TOKEN_COLOR_THEME_SYS_DARK_FOCUS_A11Y = "#4fa8ffff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY0 = "#000000ff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY5 = "#00150aff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY10 = "#002112ff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY20 = "#003822ff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY25 = "#00452bff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY35 = "#005f3dff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY30 = "#005234ff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY40 = "#006c46ff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY50 = "#008859ff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY60 = "#00a56dff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY70 = "#3ac186ff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY80 = "#5bdda0ff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY90 = "#7afabaff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY95 = "#bfffd9ff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY98 = "#e8ffeeff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY99 = "#f4fff5ff";
export const TOKEN_COLOR_THEME_REF_PRIMARY_PRIMARY100 = "#ffffffff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY0 = "#000000ff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY5 = "#000a3cff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY10 = "#001356ff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY20 = "#092582ff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY25 = "#1a328dff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY30 = "#293e98ff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY35 = "#364ba5ff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY40 = "#4357b2ff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY50 = "#5c71cdff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY60 = "#778be9ff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY70 = "#94a6ffff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY80 = "#b9c3ffff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY90 = "#dde1ffff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY95 = "#f0efffff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY98 = "#fbf8ffff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY99 = "#fefbffff";
export const TOKEN_COLOR_THEME_REF_SECONDARY_SECONDARY100 = "#ffffffff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY0 = "#000000ff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY5 = "#00131aff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY10 = "#001f27ff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY20 = "#003642ff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY25 = "#004150ff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY30 = "#004e5fff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY35 = "#005a6eff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY40 = "#00677dff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY50 = "#00809bff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY60 = "#009ebeff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY70 = "#34b9dcff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY80 = "#5ad5f9ff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY90 = "#b3ebffff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY95 = "#dbf5ffff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY98 = "#f2fbffff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY99 = "#f9fdffff";
export const TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY100 = "#ffffffff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR0 = "#000000ff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR5 = "#2d0002ff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR10 = "#410005ff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR20 = "#68000cff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR25 = "#7c0312ff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR30 = "#8d131bff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR35 = "#9e2126ff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR40 = "#af2e30ff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR50 = "#d14746ff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR60 = "#f3605dff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR70 = "#ff8983ff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR80 = "#ffb3aeff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR90 = "#ffdad7ff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR95 = "#ffedebff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR98 = "#fff8f7ff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR99 = "#fffbffff";
export const TOKEN_COLOR_THEME_REF_ERROR_ERROR100 = "#ffffffff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING0 = "#000000ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING5 = "#151100ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING10 = "#221b00ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING15 = "#2d2500ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING20 = "#3a3000ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING25 = "#463b00ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING30 = "#534600ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING35 = "#615200ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING40 = "#6e5d00ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING50 = "#857100ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING60 = "#a79014ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING70 = "#c4aa32ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING80 = "#e1c64bff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING90 = "#fee264ff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING95 = "#00000000";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING98 = "#fff9eeff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING99 = "#fffbffff";
export const TOKEN_COLOR_THEME_REF_WARNING_WARNING100 = "#ffffffff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY0 = "#000000ff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY10 = "#190e06ff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY20 = "#4c2a12ff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY30 = "#773a0eff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY40 = "#a65113ff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY50 = "#d56818ff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY60 = "#ef8132ff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY70 = "#ff9850ff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY80 = "#ffaf77ff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY90 = "#ffc69eff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY95 = "#ffddc5ff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY99 = "#fff4ecff";
export const TOKEN_COLOR_THEME_REF_SAFETY_SAFETY100 = "#ffffffff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS0 = "#000000ff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS5 = "#00150aff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS10 = "#002112ff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS15 = "#002c1aff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS20 = "#003822ff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS25 = "#00452bff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS30 = "#005234ff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS35 = "#005f3dff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS40 = "#006c46ff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS50 = "#008859ff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS60 = "#00a56dff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS70 = "#3ac186ff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS80 = "#5bdda0ff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS90 = "#7afabaff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS95 = "#bfffd9ff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS98 = "#e8ffeeff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS99 = "#f4fff5ff";
export const TOKEN_COLOR_THEME_REF_SUCCESS_SUCCESS100 = "#ffffffff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL0 = "#000000ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL5 = "#0e120fff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL10 = "#191c1aff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL20 = "#2e312eff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL25 = "#393c39ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL30 = "#444845ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL35 = "#505350ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL40 = "#5c5f5cff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL50 = "#757874ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL60 = "#8f918eff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL70 = "#a9aca8ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL80 = "#c5c7c3ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL90 = "#e1e3dfff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL95 = "#eff1edff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL98 = "#f8faf5ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL99 = "#fbfdf8ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL100 = "#ffffffff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT0 = "#000000ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT5 = "#0b130eff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT10 = "#151d18ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT20 = "#2a322dff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT25 = "#353d38ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT30 = "#404943ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT35 = "#4c544eff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT40 = "#58605aff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT50 = "#707972ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT60 = "#8a938cff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT70 = "#a4ada6ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT80 = "#c0c9c1ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT90 = "#dce5dcff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT95 = "#eaf3eaff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT98 = "#f3fcf3ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT99 = "#f6fff6ff";
export const TOKEN_COLOR_THEME_REF_NEUTRAL_VARIANT_NEUTRAL_VARIANT100 = "#ffffffff";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_96 = "#fefefef5";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_88 = "#fefefee0";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_80 = "#fefefecc";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_72 = "#fefefeb8";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_64 = "#fefefea3";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_56 = "#fefefe8f";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_48 = "#fefefe7a";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_40 = "#fefefe66";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_32 = "#fefefe52";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_24 = "#fefefe3d";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_16 = "#fefefe29";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_12 = "#fefefe1f";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_8 = "#fefefe14";
export const TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_0 = "#fefefe00";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_96 = "#020917f5";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_88 = "#020917e0";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_80 = "#020917cc";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_72 = "#020917b8";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_64 = "#020917a3";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_56 = "#0209178f";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_48 = "#0209177a";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_40 = "#02091766";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_32 = "#02091752";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_24 = "#0209173d";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_16 = "#02091729";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_12 = "#0209171f";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_8 = "#02091714";
export const TOKEN_COLOR_THEME_TRANSPARENT_DARK_0 = "#02091700";
export const TOKEN_COLOR_THEME_KEY_COLORS_PRIMARY = "#51d497ff";
export const TOKEN_COLOR_THEME_KEY_COLORS_SECONDARY = "#8397f6ff";
export const TOKEN_COLOR_THEME_KEY_COLORS_TERTIARY = "#4ca8c3ff";
export const TOKEN_COLOR_THEME_SURFACES_LIGHT_SURFACE_HOVER = "#fefefecc";
export const TOKEN_COLOR_THEME_SURFACES_LIGHT_SURFACE_ACTIVE = "#fefefef5";
export const TOKEN_COLOR_THEME_SURFACES_DARK_SURFACE_HOVER = "#000000db";
export const TOKEN_COLOR_THEME_SURFACES_DARK_SURFACE_ACTIVE = "#000000f5";
export const TOKEN_COLOR_THEME_SURFACES_GRADIENT_BG_DARK = "#1d3557ff";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_PRIMARY_OPACITY_0_08 = "#006c4614";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_PRIMARY_OPACITY_0_12 = "#006c461f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_PRIMARY_OPACITY_0_16 = "#006c4629";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_PRIMARY_OPACITY_0_08 = "#ffffff14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_PRIMARY_OPACITY_0_12 = "#ffffff1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_PRIMARY_OPACITY_0_16 = "#ffffff29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_PRIMARY_CONTAINER_OPACITY_0_08 = "#7afaba14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_PRIMARY_CONTAINER_OPACITY_0_12 = "#7afaba1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_PRIMARY_CONTAINER_OPACITY_0_16 = "#7afaba29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_PRIMARY_CONTAINER_OPACITY_0_08 = "#00211214";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_PRIMARY_CONTAINER_OPACITY_0_12 = "#0021121f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_PRIMARY_CONTAINER_OPACITY_0_16 = "#00211229";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SECONDARY_OPACITY_0_08 = "#4357b214";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SECONDARY_OPACITY_0_12 = "#4357b21f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SECONDARY_OPACITY_0_16 = "#4357b229";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SECONDARY_OPACITY_0_08 = "#ffffff14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SECONDARY_OPACITY_0_12 = "#ffffff1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SECONDARY_OPACITY_0_16 = "#ffffff29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SECONDARY_CONTAINER_OPACITY_0_08 = "#dde1ff14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SECONDARY_CONTAINER_OPACITY_0_12 = "#dde1ff1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SECONDARY_CONTAINER_OPACITY_0_16 = "#dde1ff29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SECONDARY_CONTAINER_OPACITY_0_08 = "#00135614";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SECONDARY_CONTAINER_OPACITY_0_12 = "#0013561f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SECONDARY_CONTAINER_OPACITY_0_16 = "#00135629";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_TERTIARY_OPACITY_0_08 = "#00677d14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_TERTIARY_OPACITY_0_12 = "#00677d1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_TERTIARY_OPACITY_0_16 = "#00677d29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_TERTIARY_OPACITY_0_08 = "#ffffff14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_TERTIARY_OPACITY_0_12 = "#ffffff1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_TERTIARY_OPACITY_0_16 = "#ffffff29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_TERTIARY_CONTAINER_OPACITY_0_08 = "#b3ebff14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_TERTIARY_CONTAINER_OPACITY_0_12 = "#b3ebff1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_TERTIARY_CONTAINER_OPACITY_0_16 = "#b3ebff29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_TERTIARY_CONTAINER_OPACITY_0_08 = "#001f2714";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_TERTIARY_CONTAINER_OPACITY_0_12 = "#001f271f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_TERTIARY_CONTAINER_OPACITY_0_16 = "#001f2729";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ERROR_OPACITY_0_08 = "#af2e3014";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ERROR_OPACITY_0_12 = "#af2e301f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ERROR_OPACITY_0_16 = "#af2e3029";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_ERROR_OPACITY_0_08 = "#ffffff14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_ERROR_OPACITY_0_12 = "#ffffff1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_ERROR_OPACITY_0_16 = "#ffffff29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ERROR_CONTAINER_OPACITY_0_08 = "#ffdad714";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ERROR_CONTAINER_OPACITY_0_12 = "#ffdad71f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ERROR_CONTAINER_OPACITY_0_16 = "#ffdad729";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_ERROR_CONTAINER_OPACITY_0_08 = "#41000514";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_ERROR_CONTAINER_OPACITY_0_12 = "#4100051f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_ERROR_CONTAINER_OPACITY_0_16 = "#41000529";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_OUTLINE_OPACITY_0_08 = "#70797214";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_OUTLINE_OPACITY_0_12 = "#7079721f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_OUTLINE_OPACITY_0_16 = "#70797229";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_BACKGROUND_OPACITY_0_08 = "#fbfdf814";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_BACKGROUND_OPACITY_0_12 = "#fbfdf81f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_BACKGROUND_OPACITY_0_16 = "#fbfdf829";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_BACKGROUND_OPACITY_0_08 = "#191c1a14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_BACKGROUND_OPACITY_0_12 = "#191c1a1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_BACKGROUND_OPACITY_0_16 = "#191c1a29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SURFACE_OPACITY_0_08 = "#fbfdf814";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SURFACE_OPACITY_0_12 = "#fbfdf81f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SURFACE_OPACITY_0_16 = "#fbfdf829";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SURFACE_OPACITY_0_08 = "#191c1a14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SURFACE_OPACITY_0_12 = "#191c1a1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SURFACE_OPACITY_0_16 = "#191c1a29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SURFACE_VARIANT_OPACITY_0_08 = "#dce5dc14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SURFACE_VARIANT_OPACITY_0_12 = "#dce5dc1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SURFACE_VARIANT_OPACITY_0_16 = "#dce5dc29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SURFACE_VARIANT_OPACITY_0_08 = "#40494314";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SURFACE_VARIANT_OPACITY_0_12 = "#4049431f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_ON_SURFACE_VARIANT_OPACITY_0_16 = "#40494329";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_INVERSE_SURFACE_OPACITY_0_08 = "#2e312e14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_INVERSE_SURFACE_OPACITY_0_12 = "#2e312e1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_INVERSE_SURFACE_OPACITY_0_16 = "#2e312e29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_INVERSE_ON_SURFACE_OPACITY_0_08 = "#eff1ed14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_INVERSE_ON_SURFACE_OPACITY_0_12 = "#eff1ed1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_INVERSE_ON_SURFACE_OPACITY_0_16 = "#eff1ed29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_INVERSE_PRIMARY_OPACITY_0_08 = "#5bdda014";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_INVERSE_PRIMARY_OPACITY_0_12 = "#5bdda01f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_INVERSE_PRIMARY_OPACITY_0_16 = "#5bdda029";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SHADOW_OPACITY_0_08 = "#00000014";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SHADOW_OPACITY_0_12 = "#0000001f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SHADOW_OPACITY_0_16 = "#00000029";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SURFACE_TINT_OPACITY_0_08 = "#006c4614";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SURFACE_TINT_OPACITY_0_12 = "#006c461f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SURFACE_TINT_OPACITY_0_16 = "#006c4629";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_OUTLINE_VARIANT_OPACITY_0_08 = "#c0c9c114";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_OUTLINE_VARIANT_OPACITY_0_12 = "#c0c9c11f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_OUTLINE_VARIANT_OPACITY_0_16 = "#c0c9c129";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SCRIM_OPACITY_0_08 = "#00000014";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SCRIM_OPACITY_0_12 = "#0000001f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SCRIM_OPACITY_0_16 = "#00000029";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_PRIMARY_OPACITY_0_08 = "#5bdda014";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_PRIMARY_OPACITY_0_12 = "#5bdda01f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_PRIMARY_OPACITY_0_16 = "#5bdda029";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_PRIMARY_OPACITY_0_08 = "#00382214";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_PRIMARY_OPACITY_0_12 = "#0038221f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_PRIMARY_OPACITY_0_16 = "#00382229";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_PRIMARY_CONTAINER_OPACITY_0_08 = "#00523414";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_PRIMARY_CONTAINER_OPACITY_0_12 = "#0052341f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_PRIMARY_CONTAINER_OPACITY_0_16 = "#00523429";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_PRIMARY_CONTAINER_OPACITY_0_08 = "#7afaba14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_PRIMARY_CONTAINER_OPACITY_0_12 = "#7afaba1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_PRIMARY_CONTAINER_OPACITY_0_16 = "#7afaba29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SECONDARY_OPACITY_0_08 = "#b9c3ff14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SECONDARY_OPACITY_0_12 = "#b9c3ff1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SECONDARY_OPACITY_0_16 = "#b9c3ff29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SECONDARY_OPACITY_0_08 = "#09258214";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SECONDARY_OPACITY_0_12 = "#0925821f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SECONDARY_OPACITY_0_16 = "#09258229";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SECONDARY_CONTAINER_OPACITY_0_08 = "#293e9814";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SECONDARY_CONTAINER_OPACITY_0_12 = "#293e981f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SECONDARY_CONTAINER_OPACITY_0_16 = "#293e9829";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SECONDARY_CONTAINER_OPACITY_0_08 = "#dde1ff14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SECONDARY_CONTAINER_OPACITY_0_12 = "#dde1ff1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SECONDARY_CONTAINER_OPACITY_0_16 = "#dde1ff29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_TERTIARY_OPACITY_0_08 = "#5ad5f914";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_TERTIARY_OPACITY_0_12 = "#5ad5f91f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_TERTIARY_OPACITY_0_16 = "#5ad5f929";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_TERTIARY_OPACITY_0_08 = "#00364214";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_TERTIARY_OPACITY_0_12 = "#0036421f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_TERTIARY_OPACITY_0_16 = "#00364229";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_TERTIARY_CONTAINER_OPACITY_0_08 = "#004e5f14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_TERTIARY_CONTAINER_OPACITY_0_12 = "#004e5f1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_TERTIARY_CONTAINER_OPACITY_0_16 = "#004e5f29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_TERTIARY_CONTAINER_OPACITY_0_08 = "#b3ebff14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_TERTIARY_CONTAINER_OPACITY_0_12 = "#b3ebff1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_TERTIARY_CONTAINER_OPACITY_0_16 = "#b3ebff29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ERROR_OPACITY_0_08 = "#ffb3ae14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ERROR_OPACITY_0_12 = "#ffb3ae1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ERROR_OPACITY_0_16 = "#ffb3ae29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_ERROR_OPACITY_0_08 = "#68000c14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_ERROR_OPACITY_0_12 = "#68000c1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_ERROR_OPACITY_0_16 = "#68000c29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ERROR_CONTAINER_OPACITY_0_08 = "#8d131b14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ERROR_CONTAINER_OPACITY_0_12 = "#8d131b1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ERROR_CONTAINER_OPACITY_0_16 = "#8d131b29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_ERROR_CONTAINER_OPACITY_0_08 = "#ffdad714";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_ERROR_CONTAINER_OPACITY_0_12 = "#ffdad71f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_ERROR_CONTAINER_OPACITY_0_16 = "#ffdad729";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_OUTLINE_OPACITY_0_08 = "#8a938c14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_OUTLINE_OPACITY_0_12 = "#8a938c1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_OUTLINE_OPACITY_0_16 = "#8a938c29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_BACKGROUND_OPACITY_0_08 = "#191c1a14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_BACKGROUND_OPACITY_0_12 = "#191c1a1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_BACKGROUND_OPACITY_0_16 = "#191c1a29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_BACKGROUND_OPACITY_0_08 = "#e1e3df14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_BACKGROUND_OPACITY_0_12 = "#e1e3df1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_BACKGROUND_OPACITY_0_16 = "#e1e3df29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SURFACE_OPACITY_0_08 = "#191c1a14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SURFACE_OPACITY_0_12 = "#191c1a1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SURFACE_OPACITY_0_16 = "#191c1a29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SURFACE_OPACITY_0_08 = "#c5c7c314";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SURFACE_OPACITY_0_12 = "#c5c7c31f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SURFACE_OPACITY_0_16 = "#c5c7c329";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SURFACE_VARIANT_OPACITY_0_08 = "#40494314";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SURFACE_VARIANT_OPACITY_0_12 = "#4049431f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SURFACE_VARIANT_OPACITY_0_16 = "#40494329";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SURFACE_VARIANT_OPACITY_0_08 = "#c0c9c114";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SURFACE_VARIANT_OPACITY_0_12 = "#c0c9c11f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_ON_SURFACE_VARIANT_OPACITY_0_16 = "#c0c9c129";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_INVERSE_SURFACE_OPACITY_0_08 = "#e1e3df14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_INVERSE_SURFACE_OPACITY_0_12 = "#e1e3df1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_INVERSE_SURFACE_OPACITY_0_16 = "#e1e3df29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_INVERSE_ON_SURFACE_OPACITY_0_08 = "#191c1a14";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_INVERSE_ON_SURFACE_OPACITY_0_12 = "#191c1a1f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_INVERSE_ON_SURFACE_OPACITY_0_16 = "#191c1a29";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_INVERSE_PRIMARY_OPACITY_0_08 = "#006c4614";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_INVERSE_PRIMARY_OPACITY_0_12 = "#006c461f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_INVERSE_PRIMARY_OPACITY_0_16 = "#006c4629";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SHADOW_OPACITY_0_08 = "#00000014";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SHADOW_OPACITY_0_12 = "#0000001f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SHADOW_OPACITY_0_16 = "#00000029";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SURFACE_TINT_OPACITY_0_08 = "#5bdda014";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SURFACE_TINT_OPACITY_0_12 = "#5bdda01f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SURFACE_TINT_OPACITY_0_16 = "#5bdda029";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_OUTLINE_VARIANT_OPACITY_0_08 = "#40494314";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_OUTLINE_VARIANT_OPACITY_0_12 = "#4049431f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_OUTLINE_VARIANT_OPACITY_0_16 = "#40494329";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SCRIM_OPACITY_0_08 = "#00000014";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SCRIM_OPACITY_0_12 = "#0000001f";
export const TOKEN_COLOR_THEME_STATE_LAYERS_DARK_SCRIM_OPACITY_0_16 = "#00000029";
export const TOKEN_GRADIENT_THEME_SURFACES_LIGHT_GRADIENT_MASTER = {"gradientType":"linear","rotation":114.05956592064547,"stops":[{"position":0,"color":"#fefefee6"},{"position":1,"color":"#fefefe4d"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_DARK_GRADIENT_MASTER = {"gradientType":"linear","rotation":114.05956475094308,"stops":[{"position":0,"color":"#020917e6"},{"position":1,"color":"#0209174d"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_LIGHT = {"gradientType":"linear","rotation":101.47471793541195,"stops":[{"position":0,"color":"#00a56dff"},{"position":1,"color":"#1d3557ff"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_DARK = {"gradientType":"linear","rotation":101.47471793541195,"stops":[{"position":0,"color":"#00a56dff"},{"position":1,"color":"#00677dff"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_0 = {"gradientType":"linear","rotation":159.54941724721604,"stops":[{"position":0,"color":"#34b9dcff"},{"position":1,"color":"#5c71cdff"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_1 = {"gradientType":"linear","rotation":114.05956475094308,"stops":[{"position":0,"color":"#00000059"},{"position":1,"color":"#00000026"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_DARK_0 = {"gradientType":"linear","rotation":159.54941724721604,"stops":[{"position":0,"color":"#34b9dcff"},{"position":1,"color":"#5c71cdff"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_DARK_1 = {"gradientType":"linear","rotation":114.05956475094308,"stops":[{"position":0,"color":"#00000059"},{"position":1,"color":"#00000026"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_LIGHT = {"gradientType":"linear","rotation":175.30817202957397,"stops":[{"position":0,"color":"#5ad5f9ff"},{"position":1,"color":"#004e5fff"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_DARK = {"gradientType":"linear","rotation":135,"stops":[{"position":0.11,"color":"#5ad5f9ff"},{"position":1,"color":"#00677dff"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PENDO_GRADIENT_LIGHT = {"gradientType":"linear","rotation":175.30817202957397,"stops":[{"position":0.11,"color":"#00829dff"},{"position":1,"color":"#1d3557ff"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PENDO_GRADIENT_DARK = {"gradientType":"linear","rotation":175.30817202957397,"stops":[{"position":0.11,"color":"#5ad5f9ff"},{"position":1,"color":"#00677dff"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_0 = {"gradientType":"linear","rotation":175.30817202957397,"stops":[{"position":0,"color":"#efefefff"},{"position":1,"color":"#4ca8c3ff"}]};
export const TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_1 = "#ffffff99";
export const TOKEN_FONT_TYPOGRAPHY_DISPLAY_LARGE = {"fontSize":64,"textDecoration":"none","fontFamily":"Inter","fontWeight":300,"fontStyle":"normal","fontStretch":"normal","letterSpacing":-1.28,"lineHeight":96,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_DISPLAY_MEDIUM = {"fontSize":52,"textDecoration":"none","fontFamily":"Inter","fontWeight":300,"fontStyle":"normal","fontStretch":"normal","letterSpacing":-1.04,"lineHeight":78,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_HEADLINE_H1 = {"fontSize":48,"textDecoration":"none","fontFamily":"Inter","fontWeight":300,"fontStyle":"normal","fontStretch":"normal","letterSpacing":-0.96,"lineHeight":57.6,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_HEADLINE_H2 = {"fontSize":36,"textDecoration":"none","fontFamily":"Inter","fontWeight":300,"fontStyle":"normal","fontStretch":"normal","letterSpacing":-0.72,"lineHeight":43.2,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_HEADLINE_H3 = {"fontSize":28,"textDecoration":"none","fontFamily":"Inter","fontWeight":600,"fontStyle":"normal","fontStretch":"normal","letterSpacing":-0.28,"lineHeight":34.72,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1 = {"fontSize":24,"textDecoration":"none","fontFamily":"Inter","fontWeight":600,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.24,"lineHeight":36,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_2 = {"fontSize":20,"textDecoration":"none","fontFamily":"Inter","fontWeight":600,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.2,"lineHeight":30,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_3 = {"fontSize":20,"textDecoration":"none","fontFamily":"Inter","fontWeight":400,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0,"lineHeight":30,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_TITLE_SUBHEAD_4 = {"fontSize":18,"textDecoration":"none","fontFamily":"Inter","fontWeight":600,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0,"lineHeight":27,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_BODY_BODY_LARGE = {"fontSize":18,"textDecoration":"none","fontFamily":"Inter","fontWeight":400,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0,"lineHeight":27,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM = {"fontSize":16,"textDecoration":"none","fontFamily":"Inter","fontWeight":400,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.15,"lineHeight":22.88,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS = {"fontSize":16,"textDecoration":"none","fontFamily":"Inter","fontWeight":600,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.15,"lineHeight":22.88,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL = {"fontSize":14,"textDecoration":"none","fontFamily":"Inter","fontWeight":400,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.252,"lineHeight":20.02,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS = {"fontSize":14,"textDecoration":"none","fontFamily":"Inter","fontWeight":600,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.252,"lineHeight":20.02,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_BODY_LINK = {"fontSize":14,"textDecoration":"underline","fontFamily":"Inter","fontWeight":700,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.4,"lineHeight":20,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM = {"fontSize":14,"textDecoration":"none","fontFamily":"Inter","fontWeight":700,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.4,"lineHeight":20,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE = {"fontSize":16,"textDecoration":"none","fontFamily":"Inter","fontWeight":700,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.46,"lineHeight":24,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL = {"fontSize":12,"textDecoration":"none","fontFamily":"Inter","fontWeight":600,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.46,"lineHeight":24,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM = {"fontSize":12,"textDecoration":"none","fontFamily":"Inter","fontWeight":600,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.72,"lineHeight":14.4,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL = {"fontSize":10,"textDecoration":"none","fontFamily":"Inter","fontWeight":600,"fontStyle":"normal","fontStretch":"normal","letterSpacing":1.5,"lineHeight":16.5,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE = {"fontSize":14,"textDecoration":"none","fontFamily":"Inter","fontWeight":400,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.56,"lineHeight":17.64,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT = {"fontSize":12,"textDecoration":"none","fontFamily":"Inter","fontWeight":600,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.72,"lineHeight":15.12,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"uppercase"};
export const TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP = {"fontSize":12,"textDecoration":"none","fontFamily":"Inter","fontWeight":400,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.15,"lineHeight":12,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP = {"fontSize":14,"textDecoration":"none","fontFamily":"Inter","fontWeight":500,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0,"lineHeight":24,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT = {"fontSize":16,"textDecoration":"none","fontFamily":"Inter","fontWeight":400,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.15,"lineHeight":24,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS = {"fontSize":14,"textDecoration":"none","fontFamily":"Inter","fontWeight":500,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0,"lineHeight":20,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID = {"fontSize":14,"textDecoration":"none","fontFamily":"Inter","fontWeight":500,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0.17,"lineHeight":24,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_FONT_TYPOGRAPHY_PALETTE_MATH = {"fontSize":18,"textDecoration":"none","fontFamily":"Noto Sans Math","fontWeight":400,"fontStyle":"normal","fontStretch":"normal","letterSpacing":0,"lineHeight":27,"paragraphIndent":0,"paragraphSpacing":0,"textCase":"none"};
export const TOKEN_EFFECT_DROP_SHADOW = {"shadowType":"dropShadow","radius":4,"color":"#00000040","offsetX":0,"offsetY":2,"spread":0};
export const TOKEN_EFFECT_HOVER_SHADOW = {"shadowType":"dropShadow","radius":20,"color":"#00000040","offsetX":0,"offsetY":0,"spread":0};
export const TOKEN_EFFECT_BOX_SHADOW_2 = {"shadowType":"dropShadow","radius":4,"color":"#00000040","offsetX":0,"offsetY":0,"spread":0};
export const TOKEN_EFFECT_BOX_SHADOW_4 = {"shadowType":"dropShadow","radius":8,"color":"#00000040","offsetX":0,"offsetY":0,"spread":0};
export const TOKEN_EFFECT_BOX_SHADOW_6 = {"shadowType":"dropShadow","radius":12,"color":"#00000040","offsetX":0,"offsetY":0,"spread":1};
export const TOKEN_EFFECT_BOX_SHADOW_8 = {"shadowType":"dropShadow","radius":16,"color":"#00000040","offsetX":0,"offsetY":0,"spread":2};
export const TOKEN_EFFECT_BOX_SHADOW_10 = {"shadowType":"dropShadow","radius":20,"color":"#00000040","offsetX":0,"offsetY":0,"spread":3};
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_LARGE_FONT_SIZE = 64;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_LARGE_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_LARGE_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_LARGE_FONT_WEIGHT = 300;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_LARGE_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_LARGE_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_LARGE_LETTER_SPACING = -1.28;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_LARGE_LINE_HEIGHT = 96;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_LARGE_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_LARGE_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_LARGE_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_MEDIUM_FONT_SIZE = 52;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_MEDIUM_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_MEDIUM_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_MEDIUM_FONT_WEIGHT = 300;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_MEDIUM_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_MEDIUM_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_MEDIUM_LETTER_SPACING = -1.04;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_MEDIUM_LINE_HEIGHT = 78;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_MEDIUM_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_MEDIUM_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_DISPLAY_MEDIUM_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H1_FONT_SIZE = 48;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H1_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H1_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H1_FONT_WEIGHT = 300;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H1_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H1_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H1_LETTER_SPACING = -0.96;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H1_LINE_HEIGHT = 57.6;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H1_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H1_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H1_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H2_FONT_SIZE = 36;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H2_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H2_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H2_FONT_WEIGHT = 300;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H2_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H2_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H2_LETTER_SPACING = -0.72;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H2_LINE_HEIGHT = 43.2;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H2_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H2_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H2_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H3_FONT_SIZE = 28;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H3_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H3_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H3_FONT_WEIGHT = 600;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H3_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H3_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H3_LETTER_SPACING = -0.28;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H3_LINE_HEIGHT = 34.72;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H3_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H3_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_HEADLINE_H3_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_1_FONT_SIZE = 24;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_1_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_1_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_1_FONT_WEIGHT = 600;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_1_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_1_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_1_LETTER_SPACING = 0.24;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_1_LINE_HEIGHT = 36;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_1_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_1_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_1_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_2_FONT_SIZE = 20;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_2_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_2_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_2_FONT_WEIGHT = 600;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_2_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_2_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_2_LETTER_SPACING = 0.2;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_2_LINE_HEIGHT = 30;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_2_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_2_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_2_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_3_FONT_SIZE = 20;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_3_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_3_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_3_FONT_WEIGHT = 400;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_3_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_3_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_3_LETTER_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_3_LINE_HEIGHT = 30;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_3_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_3_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBTITLE_3_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBHEAD_4_FONT_SIZE = 18;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBHEAD_4_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBHEAD_4_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBHEAD_4_FONT_WEIGHT = 600;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBHEAD_4_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBHEAD_4_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBHEAD_4_LETTER_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBHEAD_4_LINE_HEIGHT = 27;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBHEAD_4_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBHEAD_4_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_TITLE_SUBHEAD_4_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_LARGE_FONT_SIZE = 18;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_LARGE_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_LARGE_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_LARGE_FONT_WEIGHT = 400;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_LARGE_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_LARGE_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_LARGE_LETTER_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_LARGE_LINE_HEIGHT = 27;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_LARGE_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_LARGE_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_LARGE_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_FONT_SIZE = 16;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_FONT_WEIGHT = 400;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_LETTER_SPACING = 0.15;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_LINE_HEIGHT = 22.88;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS_FONT_SIZE = 16;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS_FONT_WEIGHT = 600;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS_LETTER_SPACING = 0.15;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS_LINE_HEIGHT = 22.88;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_FONT_SIZE = 14;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_FONT_WEIGHT = 400;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_LETTER_SPACING = 0.252;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_LINE_HEIGHT = 20.02;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS_FONT_SIZE = 14;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS_FONT_WEIGHT = 600;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS_LETTER_SPACING = 0.252;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS_LINE_HEIGHT = 20.02;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_LINK_FONT_SIZE = 14;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_LINK_TEXT_DECORATION = "underline";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_LINK_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_LINK_FONT_WEIGHT = 700;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_LINK_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_LINK_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_LINK_LETTER_SPACING = 0.4;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_LINK_LINE_HEIGHT = 20;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_LINK_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_LINK_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BODY_LINK_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM_FONT_SIZE = 14;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM_FONT_WEIGHT = 700;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM_LETTER_SPACING = 0.4;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM_LINE_HEIGHT = 20;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_LARGE_FONT_SIZE = 16;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_LARGE_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_LARGE_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_LARGE_FONT_WEIGHT = 700;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_LARGE_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_LARGE_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_LARGE_LETTER_SPACING = 0.46;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_LARGE_LINE_HEIGHT = 24;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_LARGE_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_LARGE_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_LARGE_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_SMALL_FONT_SIZE = 12;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_SMALL_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_SMALL_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_SMALL_FONT_WEIGHT = 600;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_SMALL_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_SMALL_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_SMALL_LETTER_SPACING = 0.46;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_SMALL_LINE_HEIGHT = 24;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_SMALL_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_SMALL_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_BUTTON_BUTTON_SMALL_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_MEDIUM_FONT_SIZE = 12;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_MEDIUM_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_MEDIUM_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_MEDIUM_FONT_WEIGHT = 600;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_MEDIUM_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_MEDIUM_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_MEDIUM_LETTER_SPACING = 0.72;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_MEDIUM_LINE_HEIGHT = 14.4;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_MEDIUM_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_MEDIUM_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_MEDIUM_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABEL_SMALL_FONT_SIZE = 10;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABEL_SMALL_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABEL_SMALL_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABEL_SMALL_FONT_WEIGHT = 600;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABEL_SMALL_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABEL_SMALL_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABEL_SMALL_LETTER_SPACING = 1.5;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABEL_SMALL_LINE_HEIGHT = 16.5;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABEL_SMALL_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABEL_SMALL_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABEL_SMALL_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_LARGE_FONT_SIZE = 14;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_LARGE_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_LARGE_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_LARGE_FONT_WEIGHT = 400;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_LARGE_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_LARGE_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_LARGE_LETTER_SPACING = 0.56;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_LARGE_LINE_HEIGHT = 17.64;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_LARGE_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_LARGE_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_LABLE_LARGE_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT_FONT_SIZE = 12;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT_FONT_WEIGHT = 600;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT_LETTER_SPACING = 0.72;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT_LINE_HEIGHT = 15.12;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT_TEXT_CASE = "uppercase";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP_FONT_SIZE = 12;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP_FONT_WEIGHT = 400;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP_LETTER_SPACING = 0.15;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP_LINE_HEIGHT = 12;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TOOLTIP_FONT_SIZE = 14;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TOOLTIP_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TOOLTIP_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TOOLTIP_FONT_WEIGHT = 500;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TOOLTIP_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TOOLTIP_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TOOLTIP_LETTER_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TOOLTIP_LINE_HEIGHT = 24;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TOOLTIP_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TOOLTIP_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TOOLTIP_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_TEXT_FONT_SIZE = 16;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_TEXT_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_TEXT_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_TEXT_FONT_WEIGHT = 400;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_TEXT_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_TEXT_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_TEXT_LETTER_SPACING = 0.15;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_TEXT_LINE_HEIGHT = 24;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_TEXT_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_TEXT_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_INPUT_TEXT_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_AVATAR_INITIALS_FONT_SIZE = 14;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_AVATAR_INITIALS_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_AVATAR_INITIALS_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_AVATAR_INITIALS_FONT_WEIGHT = 500;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_AVATAR_INITIALS_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_AVATAR_INITIALS_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_AVATAR_INITIALS_LETTER_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_AVATAR_INITIALS_LINE_HEIGHT = 20;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_AVATAR_INITIALS_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_AVATAR_INITIALS_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_AVATAR_INITIALS_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID_FONT_SIZE = 14;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID_FONT_FAMILY = "Inter";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID_FONT_WEIGHT = 500;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID_LETTER_SPACING = 0.17;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID_LINE_HEIGHT = 24;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID_TEXT_CASE = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_PALETTE_MATH_FONT_SIZE = 18;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_PALETTE_MATH_TEXT_DECORATION = "none";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_PALETTE_MATH_FONT_FAMILY = "Noto Sans Math";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_PALETTE_MATH_FONT_WEIGHT = 400;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_PALETTE_MATH_FONT_STYLE = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_PALETTE_MATH_FONT_STRETCH = "normal";
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_PALETTE_MATH_LETTER_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_PALETTE_MATH_LINE_HEIGHT = 27;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_PALETTE_MATH_PARAGRAPH_INDENT = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_PALETTE_MATH_PARAGRAPH_SPACING = 0;
export const TOKEN_TYPOGRAPHY_TYPOGRAPHY_PALETTE_MATH_TEXT_CASE = "none";