/** @format */
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	TextField,
	Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NewAssignmentStartOptions = (props) => {
	const handleHWSelection = (e) => {};

	return (
		<section className='main-content start-options'>
			<Typography
				variant='h2'
				component='h1'
				sx={{ mb: 3 }}
			>
				Select assignment type
			</Typography>
			<Accordion sx={{ mb: 2 }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel1a-content'
					id='panel1a-header'
					sx={{ px: 4, py: 2 }}
				>
					<div>
						<Typography variant='subtitle2'>Homework</Typography>
						<Typography
							variant='body2'
							color='text.secondary'
						>
							Low stakes assignment type with lorem ipsum settings
						</Typography>
					</div>
				</AccordionSummary>
				<AccordionDetails sx={{ p: 4 }}>
					<fieldset className='fieldset-noborder'>
						<div className='radio-container'>
							<div className='radio'>
								<input
									defaultChecked
									type='radio'
									name='assignment'
									id='radioOne'
									onChange={handleHWSelection}
								/>
								<label htmlFor='radioOne'>Start from scratch</label>
							</div>
							<p>
								Select questions for your assignment from MyLab's Item library.
							</p>
							<div className='input-container'>
								<TextField
									id='outlined-basic'
									label='Add name (required)'
									variant='outlined'
								/>

								<Button
									variant='contained'
									color='tertiary'
									size='large'
									onClick={() => props.updateStep('addcontent')}
								>
									Start
								</Button>
							</div>
						</div>
						<div className='radio-container'>
							<div className='radio'>
								<input
									type='radio'
									name='assignment'
									id='radioTwo'
									onChange={
										<fieldset className='gr-fieldset'>
											<div className='radio-container'>
												<div className='radio'>
													<input
														defaultChecked
														type='radio'
														name='assignment'
														id='radioOne'
														onChange={handleHWSelection}
													/>
													<label htmlFor='radioOne'>Start from scratch</label>
												</div>
												<p>
													Select questions for your assignment from MyLab's Item
													library.
												</p>
												<div className='gr-form-element name-input'>
													<TextField
														id='fromScratchName'
														label='Add name (required)'
														variant='outlined'
													/>
												</div>
											</div>
											<div className='radio-container'>
												<div className='radio'>
													<input
														type='radio'
														name='assignment'
														id='radioTwo'
														onChange={handleHWSelection}
													/>
													<label htmlFor='radioTwo'>
														Start from Pre-built assignment
													</label>
												</div>
												<p>
													Start with featured best content in an assignment and
													customize if you like.
												</p>
											</div>
											<div className='radio-container'>
												<div className='radio'>
													<input
														type='radio'
														name='assignment'
														id='radioThree'
														onChange={handleHWSelection}
													/>
													<label htmlFor='radioThree'>
														Import from another course
													</label>
												</div>
												<p>
													Select one of your courses, then copy one or more
													assignments. Customize as needed.
												</p>
											</div>
										</fieldset>
									}
								/>
								<label htmlFor='radioTwo'>
									Start from Pre-built assignment
								</label>
							</div>
							<p>
								Start with featured best content in an assignment and customize
								if you like.
							</p>
						</div>
						<div className='radio-container'>
							<div className='radio'>
								<input
									type='radio'
									name='assignment'
									id='radioThree'
									onChange={handleHWSelection}
								/>
								<label htmlFor='radioThree'>Import from another course</label>
							</div>
							<p>
								Select one of your courses, then copy one or more assignments.
								Customize as needed.
							</p>
						</div>
					</fieldset>
				</AccordionDetails>
			</Accordion>
			<Accordion sx={{ mb: 2 }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel2a-content'
					id='panel2a-header'
					sx={{ px: 4, py: 2 }}
				>
					<div>
						<Typography variant='subtitle2'>Quiz</Typography>
						<Typography
							variant='body2'
							color='text.secondary'
						>
							High stakes assignment type with lorem ipsum settings
						</Typography>
					</div>
				</AccordionSummary>
				<AccordionDetails sx={{ p: 4 }}></AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel3a-content'
					id='panel3a-header'
					sx={{ px: 4, py: 2 }}
				>
					<div>
						<Typography variant='subtitle2'>Test</Typography>
						<Typography
							variant='body2'
							color='text.secondary'
						>
							High states assignment type with lorem ipsum settings, lockdown
							options and companion options.
						</Typography>
					</div>
				</AccordionSummary>
				<AccordionDetails sx={{ p: 4 }}>
					<Typography>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
						malesuada lacus ex, sit amet blandit leo lobortis eget.
					</Typography>
				</AccordionDetails>
			</Accordion>
		</section>
	);
};

export default NewAssignmentStartOptions;
