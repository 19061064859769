import { createSvgIcon } from '@mui/material';

const Difficulty3 = createSvgIcon(
    <>
        <g clip-path="url(#clip0_16356_366250)">
            <circle cx="4" cy="4" r="4" fill="#AF2E30"/>
            <circle cx="16" cy="4" r="4" fill="#AF2E30"/>
            <circle cx="28" cy="4" r="4" fill="#AF2E30"/>
            <circle cx="40" cy="4" r="4" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_16356_366250">
                <rect width="44" height="8" fill="white"/>
            </clipPath>
        </defs>
    </>
    ,
  'Difficulty3'
);

export default Difficulty3;
