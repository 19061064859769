/** @format */

import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Header';
import MainNav from './MainNav';
import Dashboard from './dashboard/Dashboard';
import Assignments from './assignments/Assignments';
import AssignmentsOld from './assignments/AssignmentsOld';
import CreateAssignment from './assignments/CreateAssignment';
import Blank from './Blank';
import EText from './etext/EText';
import MediaLibrary from './medialibrary/MediaLibrary';
import CourseRoster from './courseroster/CourseRoster';
import DocumentSharing from './documentsharing/DocumentSharing';
import CourseDetails from './coursedetails/CourseDetails';
import Gradebook from './gradebook/Gradebook';

import pearsonLogo from './assets/images/logo_pearson.png';
import { Link } from '@mui/material';

const App = (props) => {
	var location = window.location;

	//Load this string from localStorage
	const lastActiveNav = localStorage.getItem('lastActiveNav');

	const [activeNav, setActiveNav] = useState(
		lastActiveNav || props.defaultActive
	);
	const [appMode, setAppMode] = useState('standalone');

	//This sets the item to localStorage and changes the state index
	function changeActiveNav(newNav) {
		localStorage.setItem('lastActiveNav', newNav);
		setActiveNav(newNav);
	}

	//This re-renders when the route changes
	useEffect(() => {
		var activeNavItem = location.pathname;
		if (activeNavItem.indexOf('/') > -1) {
			activeNavItem =
				location.pathname.split('/')[location.pathname.split('/').length - 1];
		}
		changeActiveNav(activeNavItem);
		if (location.pathname.indexOf('/page') > -1) {
			setAppMode('ov2');
			document.getElementsByTagName('body')[0].classList.add('in-ov2');
		}
	}, [location]);

	return (
		<div className='body-wrap'>
			{appMode === 'standalone' && (
				<aside className='sidebar'>
					<Link
						href='prototypes/modern-xl-instructor/'
						className='header-logo'
					>
						<img
							src={pearsonLogo}
							alt=''
						/>
					</Link>
					<MainNav activeNav={activeNav} />
				</aside>
			)}
			<main>
				{appMode === 'standalone' && <Header />}
				<BrowserRouter basename='/prototypes/modern-xl-instructor'>
					<Routes>
						<Route
							path='/'
							element={<Dashboard theme={props.theme} />}
						/>
						<Route
							path='/page'
							element={<Dashboard theme={props.theme} />}
						/>
						<Route
							path='/assignments'
							element={<Assignments />}
						/>
						<Route
							path='/page/assignments'
							element={<Assignments />}
						/>
						<Route
							path='/assignments/create-assignment'
							element={
								<CreateAssignment assignmentName='Chapter 3 Cells &amp; Tissues' />
							}
						/>
						<Route
							path='/assignments/review'
							element={
								<CreateAssignment
									assignmentName='Chapter 3 Cells &amp; Tissues'
									step={'addcontent'}
									inReview={true}
									{...props}
								/>
							}
						/>
						<Route
							path='page/assignments/review'
							element={
								<CreateAssignment
									assignmentName='Chapter 3 Cells &amp; Tissues'
									step={'addcontent'}
									inReview={true}
									{...props}
								/>
							}
						/>
						<Route
							path='/page/assignments/create-assignment'
							element={
								<CreateAssignment assignmentName='Chapter 3 Cells &amp; Tissues' />
							}
						/>
						<Route
							path='/eTextbook'
							element={<EText />}
						/>
						<Route
							path='/page/eTextbook'
							element={<EText />}
						/>
						<Route
							path='/page/media'
							element={<MediaLibrary mode={'full-page'} />}
						/>
						<Route
							path='/page/sharing'
							element={<DocumentSharing mode={'full-page'} />}
						/>
						<Route
							path='/page/roster'
							element={<CourseRoster mode={'full-page'} />}
						/>
						<Route
							path='/page/details'
							element={<CourseDetails mode={'full-page'} />}
						/>
						<Route
							path='/page/gradebook'
							element={<Gradebook mode={'full-page'} />}
						/>
						<Route
							path='/page/assignmentsold'
							element={<AssignmentsOld mode={'full-page'} />}
						/>

						<Route
							path='*'
							element={<Blank mode={'full-page'} />}
						/>
					</Routes>
				</BrowserRouter>
			</main>
		</div>
	);
};

export default App;
