import { createSvgIcon } from '@mui/material';

const QuestionOutlined = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M17.7398 4.36581H15.1976L13.3307 2.53678C12.6157 1.82107 11.3843 1.82107 10.6693 2.53678L8.80238 4.36581H6.2999C5.22741 4.36581 4.39325 5.24056 4.39325 6.27435V8.81909L2.56604 10.6481C1.81132 11.4036 1.81132 12.5964 2.56604 13.3519L4.39325 15.1809V17.7256C4.39325 18.7992 5.22741 19.6342 6.2999 19.6342H8.8421L10.6693 21.4632C11.3843 22.1789 12.6157 22.1789 13.3307 21.4632L15.1579 19.6342H17.7398C18.7726 19.6342 19.6465 18.7992 19.6465 17.7256V15.1809L21.434 13.3519C22.1887 12.5964 22.1887 11.4036 21.434 10.6481L19.6465 8.81909V6.27435C19.6465 5.24056 18.7726 4.36581 17.7398 4.36581ZM6.2999 8.81909V6.27435H8.80238C9.31877 6.27435 9.79543 6.07555 10.1529 5.71769L11.9801 3.88867L13.8471 5.71769C14.2046 6.07555 14.6812 6.27435 15.1976 6.27435H17.7398V8.81909C17.7398 9.33598 17.9384 9.81312 18.2959 10.171L20.0834 12L18.2959 13.829C17.9384 14.1869 17.7398 14.664 17.7398 15.1809V17.7256H15.1579C14.6415 17.7256 14.1648 17.9642 13.8073 18.3221L12.0199 20.1113L10.1927 18.3221C9.83515 17.9642 9.35849 17.7256 8.8421 17.7256H6.2999V15.1809C6.2999 14.664 6.06157 14.1869 5.70407 13.829L3.91658 12L5.70407 10.171C6.06157 9.81312 6.2999 9.33598 6.2999 8.81909ZM9.99654 10.2574C9.85999 10.2574 9.74747 10.147 9.75575 10.0107C9.84634 8.52015 10.9902 8 12.0344 8C13.1922 8 14.25 8.65064 14.25 9.99717C14.25 10.959 13.7237 11.4173 13.2185 11.8303C12.608 12.3281 12.3818 12.5149 12.3818 13.1542V13.221C12.3818 13.3591 12.2698 13.471 12.1318 13.471H11.5445C11.4077 13.471 11.2962 13.361 11.2945 13.2242L11.2924 13.058C11.2608 12.232 11.7029 11.7228 12.2607 11.2871C12.7501 10.8911 13.0606 10.6308 13.0606 10.0651C13.0606 9.32956 12.5396 9.02405 11.9713 9.02405C11.316 9.02405 10.944 9.43727 10.8623 10.0082C10.8427 10.1449 10.7331 10.2574 10.5951 10.2574H9.99654ZM11.8818 16C11.3976 16 11.045 15.6492 11.045 15.174C11.045 14.6818 11.3976 14.3366 11.8818 14.3366C12.387 14.3366 12.7344 14.6818 12.7344 15.174C12.7344 15.6492 12.387 16 11.8818 16Z"
    fill="currentColor"
  />,
  'QuestionOutlined'
);

export default QuestionOutlined;
