/** @format */

// State held component not using Redux
import { useState } from 'react';
import AssignmentCart from './AssignmentCart';
import EditOutlined from '../Icons/EditOutlined';
import { IconButton, Typography } from '@mui/material';

const CreateAssignmentHeader = (props) => {
	const [editMode, setEditMode] = useState(false);

	/*const updateAssignmentName = (e) => {
		let newName = document.getElementById('assignmentName').value;
		if (newName.length === 0) {
			newName = 'Chapter 3 Cells &amp; Tissues';
		}
		props.setAssignmentName(newName);
		editAssignmentName();
	};*/

	const editAssignmentName = () => {
		setEditMode(!editMode);
	};

	return (
		<header className='create-assignment-header'>
			<div className='assignment-name-block'>
				{
					/*editMode ? (
					<form
						onSubmit={(e) => updateAssignmentName(e)}
						onReset={editAssignmentName}
						className='edit-name-form'
					>
						<TextField
							name='assignment-name'
							id='assignment-name'
							label='Assignment Name'
						/>
						<IconButton type='submit'>
							<SaveOutlinedIcon />
						</IconButton>
						<IconButton type='reset'>
							<CancelOutlinedIcon />
						</IconButton>
					</form>
				) : ( */
					<Typography
						variant='subtitle2'
						component='h2'
						role='button'
						onClick={editAssignmentName}
					>
						{props.assignmentName}
						<IconButton sx={{ ml: '8px' }}>
							<EditOutlined />
						</IconButton>
					</Typography>
					/*)*/
				}
			</div>

			<AssignmentCart
				selectedCount={props.selectedCount}
				inReview={props.inReview}
				followup={props.followup}
				reviewAssignment={props.reviewAssignment}
				addAssignment={props.addAssignment}
				assignmentType={props.assignmentType}
				createStep={props.createStep}
				setStep={props.setStep}
				assignmentName={props.assignmentName}
			/>
		</header>
	);
};

export default CreateAssignmentHeader;
