/** @format */

import pageAssignmentsOld from '../assets/images/page_assignments_old.png';

export default function AssignmentsOld(props) {
	return (
		<div className={`old-page ${props.mode}`}>
			<h2 className='page-title'>Assignments</h2>
			<a href='assignments/create-assignment'>
				<img
					src={pageAssignmentsOld}
					alt=''
					style={{ display: 'block', margin: '0 auto' }}
				/>
			</a>
		</div>
	);
}
