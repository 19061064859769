/** @format */

// State held component not using Redux
import React, { useState } from 'react';
import { Chip, Typography, Box, Stack, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CheckIcon from './CheckIcon';
import { v4 as uuid } from 'uuid';

const Filters = (props) => {
	//const [objectiveOptions, setObjectiveOptions] = useState([]);
	/*const sourceOptions = [
		{ name: 'Publisher Items', id: 1 },
		{ name: 'My items', id: 2 },
		{ name: 'Test bank', id: 3 },
		{ name: 'Geogebra', id: 4 },
	];*/
	const mediaTypeOptions = [
		{ name: 'Activity', id: 2 },
		{ name: 'Animation', id: 3 },
		{ name: 'Chapter test prep videos', id: 4 },
		{ name: 'Geogebra', id: 5 },
		{ name: 'Interactive figure', id: 6 },
		{ name: 'Textbook', id: 7 },
		{ name: 'Video', id: 8 },
	];
	const questionTypeOptions = [
		{ name: 'Publisher questions', id: 1 },
		{ name: 'Geogebra', id: 2 },
		{ name: 'Test bank', id: 3 },
		{ name: 'Custom questions', id: 4 },
		{ name: 'Algorithmic', id: 5 },
		{ name: 'Accessible', id: 6 },
		{ name: 'Multiple choice', id: 7 },
		{ name: 'Short answer', id: 8 },
		{ name: 'Static', id: 9 },
		{ name: 'True/False', id: 10 },
		{ name: 'Essay', id: 11 },
	];
	const [selectedValues, setSelectedValues] = useState([]);
	/*const [filterSelected, setFilterSelected] = useState(false);*/

	/*useEffect(() => {
		let tempObjectives = [];
		let item;
		props.objectives.map((objective, i) => {
			tempObjectives.push({ name: objective, id: i });
		});
		setObjectiveOptions(tempObjectives);
	}, [props.objectives]); */

	const resetAllFilters = () => {
		setSelectedValues([]);
	};

	const resetAFilter = (cid) => {
		let tempSelectedValues = selectedValues.filter((a) => a.cid !== cid);
		setSelectedValues(tempSelectedValues);
	};

	const removeFromAllSelected = (item) => {
		let currSelections = selectedValues;
		let index = 0;

		index = currSelections.indexOf(item);
		currSelections.splice(index, 1);

		setSelectedValues([...currSelections]);
	};

	const addToAllSelected = (item) => {
		let tempSelectedValues = selectedValues;
		tempSelectedValues.push(item);
		setSelectedValues([...tempSelectedValues]);
	};

	const renderSelectedList = () => {
		let tempSelectedValues = selectedValues;
		return tempSelectedValues.map((value, index) => (
			<Chip
				key={index}
				label={value.name ? value.name : value}
				onDelete={() => removeFromAllSelected(value)}
				size='small'
				deleteIcon={
					<ClearIcon
						fontSize='small'
						color={'#000'}
					/>
				}
				sx={{ borderRadius: '4px' }}
				className='selected'
			/>
		));
	};

	const onSelectFilter = (itemVal) => {
		if (selectedValues.indexOf(itemVal) > -1) {
			removeFromAllSelected(itemVal);
		} else {
			addToAllSelected(itemVal);
		}
	};

	return (
		<div className='search-filter'>
			<div className='top-line'>
				<div className='form-element'>
					<label htmlFor='chapterSelect'>Chapter</label>
					<div className='select-container'>
						<select
							id='chapterSelect'
							onChange={(e) => props.filterByChapter(e.target.value)}
						>
							{props.chapters.map((chapter) => {
								return (
									<option
										value={chapter.value}
										key={uuid()}
										{...(chapter.value === props.selectedChapter && {
											selected: true,
										})}
									>
										{chapter.chapter}
									</option>
								);
							})}
						</select>
						<ArrowDropDownOutlinedIcon />
					</div>
				</div>

				<div className='form-element'>
					<label htmlFor='sectionSelect'>Section</label>
					<div className='select-container'>
						<select
							id='sectionSelect'
							onChange={(e) => props.filterBySection(e.target.value)}
							{...(props.selectedChapter === 'empty'
								? { disabled: true }
								: { disabled: false })}
						>
							<option value='all'>All sections</option>
							{props.sections.map((section) => {
								return (
									<option
										value={section}
										key={uuid()}
										{...(section === props.selectedSection && {
											selected: true,
										})}
									>
										{section}
									</option>
								);
							})}
						</select>
						<ArrowDropDownOutlinedIcon />
					</div>
				</div>

				<button
					className='filter-btn'
					disabled={props.selectedChapter === 'empty'}
					onClick={props.toggleFilterModal}
				>
					More filters
					<ArrowDropDownOutlinedIcon />
				</button>
			</div>
			{props.filterOpen && (
				<>
					<span
						className='backdrop'
						onClick={props.closeFilterModal}
					></span>
					<Box
						className='additional-filter-content'
						sx={{ p: 4 }}
					>
						<Stack
							direction='row'
							sx={{ mb: 2 }}
						>
							<Typography
								variant='label2'
								sx={{ minWidth: '80px', mt: 1 }}
							>
								Question
							</Typography>
							<div>
								{questionTypeOptions.map((item, i) => {
									return (
										<Chip
											label={item.name}
											size='small'
											key={`filter${i}`}
											sx={{ mr: 4, mb: 3, borderRadius: 1, px: 3 }}
											onClick={() => onSelectFilter(item.name)}
											icon={<CheckIcon />}
											className={`${
												selectedValues.indexOf(item.name) > -1 ? 'selected' : ''
											}`}
										/>
									);
								})}
							</div>
						</Stack>
						<Stack direction='row'>
							<Typography
								variant='label2'
								sx={{ minWidth: '80px', mt: 1 }}
							>
								Media
							</Typography>
							<div>
								{mediaTypeOptions.map((item, i) => {
									return (
										<Chip
											label={item.name}
											size='small'
											key={`filter${i}`}
											onClick={() => addToAllSelected(item.name)}
											sx={{ mr: 4, mb: 3, borderRadius: 1, px: 3 }}
											icon={
												<CheckIcon
													fontSize='small'
													color='black'
												/>
											}
											className={`${
												selectedValues.indexOf(item.name) > -1 ? 'selected' : ''
											}`}
										/>
									);
								})}
							</div>
							<Button
								variant='text'
								color='secondary'
								onClick={resetAllFilters}
								sx={{
									position: 'absolute',
									bottom: '0.75rem',
									right: '0',
								}}
							>
								Clear all
							</Button>
						</Stack>
					</Box>
				</>
			)}
			{selectedValues.length > 0 && !props.filterOpen && (
				<Box className='selected-filters'>
					<Typography
						variant='label2'
						my={1}
					>
						Selected filters:
					</Typography>
					<Box sx={{ display: 'flex', gap: '0.875rem', marginLeft: '1rem' }}>
						{renderSelectedList()}
					</Box>
				</Box>
			)}
		</div>
	);
};

export default Filters;
