/** @format */

// State held component not using Redux
import React, { Component, Fragment } from 'react';

class EmptyState extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {}

	render() {
		return (
			<section className='empty-state'>
				<p>Start by selecting a chapter</p>
			</section>
		);
	}
}

export default EmptyState;
