/** @format */

// State held component not using Redux
import React, { useEffect, useState } from 'react';
import units from '../assets/data/reviewData.json'; //used only when direct linking to review page
import SortableTree, { changeNodeAtPath } from 'react-sortable-tree';
import CustomNodeContentRenderer from './CustomNodeContentRenderer';
import { Button, IconButton, Tooltip } from '@mui/material';
import DifficultyLevel from './DifficultyLevel';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlined from './DeleteOutlined';
import Pool from '../Icons/Pool';
import UnPool from '../Icons/UnPool';
import ItemMenu from '../shared/ItemMenu';
import GearOutlined from '../Icons/GearOutlined';

// a little function to help us with reordering the result
/*const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};*/

const ReviewListHW = (props) => {
	const [items, setItems] = useState([]);
	const [itemsSelected, setItemsSelected] = useState(0);
	const [nosPools, setNosPools] = useState(0);
	const [poolsSelected, setPoolsSelected] = useState(0);
	const [childrenSelected, setChildrenSelected] = useState(0);

	const updateData = (updatedTreeData) => {
		console.log('Tree updated', updatedTreeData);
		let tempData = updatedTreeData.treeData
			? updatedTreeData.treeData
			: updatedTreeData;
		tempData.forEach((item) => {
			item.isLastChild = false;
			if (item.children && item.children.length > 0) {
				item.children.forEach((child, i) => {
					child.isLastChild = false;
					if (i === item.children.length - 1) {
						child.isLastChild = true;
					}
				});
			}
		});
		setItems([...tempData]);
	};

	/* Function to filter items */
	const filterItems = (items, fn) =>
		items.reduce((acc, item) => {
			if (item.children)
				return [...acc, { ...item, children: filterItems(item.children, fn) }];
			else if (fn(item)) return [...acc, item];
			return acc;
		}, []);

	const setRowHeight = ({ node, path }) => {
		var nodeHeight = 80; //Default node height is 80px

		//if it's the last child of a pool, height should be 80 + 16 (to allow for a gap)
		if (node.isLastChild) {
			nodeHeight = 96;
		}

		//If it's an item that is followed by a pool, height should be 80 + 16 (to allow for a gap)

		var nodeIndex = items.findIndex((a) => a.id === node.id);
		var nextChildIndex = parseInt(nodeIndex) + 1;
		if (
			path.length === 1 &&
			items[nextChildIndex] &&
			items[nextChildIndex].children &&
			items[nextChildIndex].children.length > 0
		) {
			nodeHeight = 96;
		}

		// if it's a pool title, height should be 54px
		if (node.children && node.children.length > 0) {
			nodeHeight = 54;

			/* if pool is collapsed, adding some space at the bottom (54 + 16 = 70) */
			if (node.expanded === false) {
				nodeHeight = 70;
			}
		}

		return nodeHeight;
	};

	const removeItems = (node) => {
		console.log(node);
		let tempItems, newItems;

		/* Function to filter items
		const filterItems = (items, fn) =>
			items.reduce((acc, item) => {
				if (item.children)
					return [
						...acc,
						{ ...item, children: filterItems(item.children, fn) },
					];
				else if (fn(item)) return [...acc, item];
				return acc;
			}, []); */

		/* check if "remove" was clicked from the 3 dot menu*/
		if (node.id) {
			tempItems = filterItems(items, (item) => item.id !== node.id);

			/* remove from header button */
		} else {
			tempItems = filterItems(items, (item) => item.selectedToRemove !== true);
		}

		newItems = tempItems.filter((a) => !a.children || a.children.length >= 1);
		setItems(newItems);
		setItemsSelected(0);
		props.updateSelectionsWithPool(newItems);
	};

	const selectItem = (e, itemId) => {
		/*if (e.target.type === 'text') {
			e.target.removeAttribute('readonly');
		} else { */
		var allItems = items,
			count = itemsSelected,
			numPoolsSelected = poolsSelected,
			numChildrenSelected = childrenSelected;

		allItems.map((item, i) => {
			if (item.id === itemId) {
				item.selectedToRemove = !item.selectedToRemove;

				if (item.selectedToRemove) {
					item.expanded = true;
					/* is a pool selected */
					if (
						e.target.closest('.rst__rowWrapper').classList.contains('pool-top')
					) {
						numPoolsSelected++;
						/* Mark all the children as parent selected */
						item.children.map((child) => {
							child.isParentSelected = true;
							return true;
						});
					}
					count++;
				} else {
					item.expanded = false;
					/* is a pool selected */
					if (
						e.target.closest('.rst__rowWrapper').classList.contains('pool-top')
					) {
						numPoolsSelected--;
						/* Remove all the children as parent selected */
						item.children.map((child) => {
							child.isParentSelected = false;
							return true;
						});
					}
					count--;
				}
			} else if (item.children) {
				item.children.map((child) => {
					if (child.id === itemId) {
						child.selectedToRemove = !child.selectedToRemove;
						if (child.selectedToRemove) {
							numChildrenSelected++;
							count++;
						} else {
							numChildrenSelected--;
							count--;
						}
					}
				});
			}
			return true;
		});

		setItems(allItems);
		setItemsSelected(count);
		setPoolsSelected(numPoolsSelected);
		setChildrenSelected(numChildrenSelected);
		//}
	};

	const unPool = (node) => {
		let tempItems;

		if (node.id) {
			tempItems = items.map((item) =>
				item.children
					? {
							...item,
							children: item.children.map((child) =>
								child.id === node.id
									? { ...child, selectedToRemove: true }
									: { ...child }
							),
					  }
					: { ...item }
			);
		} else {
			tempItems = items;
		}

		console.log(tempItems);

		let pools = tempItems.filter((a) => a.selectedToRemove),
			tempData = tempItems.filter((a) => !a.selectedToRemove);

		if (pools.length === 0) {
			pools = tempItems.filter((a) => a.children);
			tempData = tempItems.filter((a) => !a.children);
			var childrenToUnpool = [];
			pools.forEach((item) => {
				item.children.forEach((child, i) => {
					if (child.selectedToRemove) {
						childrenToUnpool.push(child);
					}
				});
				/* Remove all the children that are marked for unpooling */
				var tempChildren = item.children.filter((a) => !a.selectedToRemove);
				item.children = tempChildren;
			});

			// Add all the un pooled children to tempdata
			childrenToUnpool.forEach((child) => {
				child.selectedToRemove = false;
				child.isParentSelected = false;
				child.isLastChild = false;
				tempData.unshift(child);
			});

			//Add the modified pools to tempdata - if only 1 item is left in the pool, unpool it.
			pools.forEach((item) => {
				if (item.children.length > 1) {
					tempData.unshift(item);
				} else {
					item.children.map((child) => {
						child.isLastChild = false;
						tempData.unshift(child);
					});
				}
			});
		} else {
			pools.forEach((item) => {
				item.children.forEach((child) => {
					child.isParentSelected = false;
					child.isLastChild = false;
					child.selectedToRemove = false;
					tempData.unshift(child);
				});
			});
		}

		setItems([...tempData]);
		setPoolsSelected(0);
		setItemsSelected(0);
		setNosPools(nosPools - 1);

		props.updateSelectionsWithPool(tempData);
	};

	const createPool = () => {
		var poolName = `Pool`;
		let poolItem = {};

		poolItem.name = poolName;
		poolItem.points = '1';
		poolItem.noChildren = false;
		poolItem.expanded = true;
		poolItem.selectedToRemove = false;
		poolItem.id = poolName
			.toLowerCase()
			.replace(/\s/g, '')
			.concat('_', nosPools + 1);
		poolItem.order = items.length;

		/* Grabbing items to be in the pool */
		var itemsInPool = items.filter((a) => a.selectedToRemove);

		/* Check if any of the selected items is a pool */
		let poolWasSelected = false;
		let nonPoolItems = [];

		itemsInPool.forEach((item) => {
			if (item.children && item.children.length > 0) {
				poolWasSelected = true;
				poolItem = item;
			} else {
				nonPoolItems.push(item);
			}
		});

		/* Removing items selected for pooling */
		var tempData = items.filter((a) => !a.selectedToRemove);

		itemsInPool.forEach((item) => {
			item.selectedToRemove = false;
		});

		if (poolWasSelected) {
			poolItem.selectedToRemove = false;
			nonPoolItems.forEach((item) => {
				poolItem.children.push(item);

				/* remove last item indicator for all items in the pool*/
				poolItem.children.forEach((item, i) => {
					item.isLastChild = false;
					item.isParentSelected = false;
				});
			});
			setPoolsSelected(poolsSelected - 1);
		} else {
			poolItem.children = itemsInPool;
		}

		//Add an indicator to the last child for setting height when rendering
		itemsInPool.forEach((item) => {
			item.isLastChild = false;
		});
		itemsInPool[itemsInPool.length - 1].isLastChild = true;

		tempData.unshift(poolItem);

		setItems([...tempData]);
		setItemsSelected(0);
		if (!poolWasSelected) {
			setNosPools(nosPools + 1);
		}

		props.updateSelectionsWithPool(tempData);
	};

	const generateNodeProps = ({ node, path, lowerSiblingCounts }) => {
		var classToAdd = '';

		if (lowerSiblingCounts.length > 0 && lowerSiblingCounts[1] === 0) {
			classToAdd = 'last-child';
		}
		if (
			(node.children && node.children.length > 0) ||
			typeof node.children === 'function'
		) {
			classToAdd = 'pool-top';
		} else if (path.length > 1) {
			classToAdd += ' pool-child';
		}

		return {
			selectitem: selectItem,
			className: classToAdd,
			buttons: [
				<div className='item-difficulty'>
					<DifficultyLevel difficulty={node.difficulty} />
				</div>,
				<span className='points-fld'>
					<input
						type='text'
						defaultValue={node.points}
					/>
					pts
				</span>,
				<span className='item-options'>
					<ItemMenu
						title={node.title}
						menuNodeKey={node.id}
						currentNode={node}
						removeItems={removeItems}
						unPool={unPool}
					/>
				</span>,
				/*<IconButton
					aria-label='more'
					className='more-btn'
					onClick={(e) => e.stopPropagation()}
				>
					<ThreeDotEllipses />
				</IconButton>,*/
			],
		};
	};

	useEffect(() => {
		/* This happens only when coming directly to the review page */
		if (props.data.length === 0) {
			setItems(units);
		} else {
			setItems(props.data);
		}
	}, []);

	const getNodeKey = ({ treeIndex }) => treeIndex;
	return (
		<div className='review-content'>
			<header>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<h3>My selections</h3>
					<Tooltip
						title='Add more items to this assignment'
						slotProps={{
							popper: {
								modifiers: [
									{
										name: 'offset',
										options: {
											offset: [0, -14],
										},
									},
								],
							},
						}}
					>
						<Button
							onClick={props.addMore}
							variant='text'
							sx={{ color: '#020917' }}
							startIcon={<AddIcon fontSize='small' />}
						>
							Add
						</Button>
					</Tooltip>

					<Tooltip
						title='Select an item to remove'
						slotProps={{
							popper: {
								modifiers: [
									{
										name: 'offset',
										options: {
											offset: [0, -14],
										},
									},
								],
							},
						}}
					>
						<span>
							<Button
								variant='text'
								sx={{ color: '#020917' }}
								startIcon={<DeleteOutlined fontSize='small' />}
								aria-disabled={itemsSelected <= 0}
								onClick={removeItems}
							>
								Remove
							</Button>
						</span>
					</Tooltip>

					<Tooltip
						title='Select 2 or more items to pool'
						slotProps={{
							popper: {
								modifiers: [
									{
										name: 'offset',
										options: {
											offset: [0, -14],
										},
									},
								],
							},
						}}
					>
						<span>
							<Button
								variant='text'
								sx={{ color: '#020917' }}
								aria-disabled={itemsSelected <= 1}
								style={{ marginRight: '4px' }}
								onClick={createPool}
							>
								<Pool />
								Pool
							</Button>
						</span>
					</Tooltip>

					<Tooltip
						title='Select a pool'
						slotProps={{
							popper: {
								modifiers: [
									{
										name: 'offset',
										options: {
											offset: [0, -14],
										},
									},
								],
							},
						}}
					>
						<span>
							<Button
								variant='text'
								sx={{ color: '#020917' }}
								aria-disabled={
									!(
										(poolsSelected > 0 && itemsSelected === poolsSelected) ||
										(poolsSelected === 0 &&
											itemsSelected === childrenSelected &&
											childrenSelected > 0)
									)
								}
								style={{ marginRight: '16px', marginLeft: '4px' }}
								onClick={unPool}
							>
								<UnPool />
								Unpool
							</Button>
						</span>
					</Tooltip>
				</div>
				<div style={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
					<strong style={{ marginRight: '8px' }}>Total:</strong>
					<div className='assignment-cart__items'>
						<span className='count'>{items.length} items</span>
					</div>{' '}
					<span style={{ color: '#C0C9C1' }}>|</span>
					<div className='assignment-cart__minutes'>
						<span className='count'>{items.length} min</span>
					</div>{' '}
					<span style={{ color: '#C0C9C1' }}>|</span>
					<div className='assignment-cart__points'>
						<span className='count'>{items.length} pts</span>
					</div>
					<IconButton>
						<GearOutlined />
					</IconButton>
				</div>
			</header>
			<section className='table-wrapper assignment-manager-list-view review-table'>
				<SortableTree
					isVirtualized={false}
					getNodeKey={({ node }) => node.id}
					treeData={items}
					onChange={(treeData) => updateData(treeData)}
					canNodeHaveChildren={(node) => !node.noChildren}
					rowHeight={setRowHeight}
					nodeContentRenderer={CustomNodeContentRenderer}
					generateNodeProps={generateNodeProps}
				/>
			</section>
		</div>
	);
};

export default ReviewListHW;
