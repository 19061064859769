/** @format */

import pageCourseRoster from '../assets/images/page_course_roster.png';

export default function CourseRoster(props) {
	return (
		<div className={`old-page ${props.mode}`}>
			<h2 className='page-title'>Course Roaster</h2>
			<img
				src={pageCourseRoster}
				alt=''
				style={{ display: 'block', margin: '0 auto' }}
			/>
		</div>
	);
}
