/** @format */

// State held component not using Redux
import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import ThreeDotEllipses from '../Icons/ThreeDotEllipses';

const ITEM_HEIGHT = 48;
const OptionsMenu = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<div className='options-menu'>
			<IconButton
				aria-label='more'
				id='long-button'
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup='true'
				onClick={handleClick}
			>
				<ThreeDotEllipses />
			</IconButton>
			<Menu
				id='long-menu'
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{props.options.map((option) => (
					<MenuItem
						key={option}
						selected={option === 'Pyxis'}
						onClick={handleClose}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};
export default OptionsMenu;
