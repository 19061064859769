/** @format */

// State held component not using Redux
import { Button, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const AssignmentCart = (props) => {
	return (
		<div className='assignment-cart'>
			<Button
				color='secondary'
				variant='text'
			>
				Exit
			</Button>
			<Button
				color='primary'
				variant='outlined'
				disabled={props.selectedCount <= 0}
			>
				Save
			</Button>

			<Button
				variant='contained'
				color='tertiary'
				onClick={props.reviewAssignment}
				disabled={props.selectedCount <= 0}
			>
				Review
			</Button>
			<Button
				variant='contained'
				color='tertiary'
				disabled={props.selectedCount <= 0}
			>
				Preview
			</Button>
		</div>
	);
};

export default AssignmentCart;
