/** @format */

import OptionsMenu from '../shared/OptionsMenu';
import Switch from '../shared/Switch';
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const ListTableItem = (props) => {
	const menuOptions = [
		{ option: 'Edit...' },
		{ option: 'Copy' },
		{ option: 'Delete' },
		{ option: 'Assignment settings' },
		{
			option: 'Student settings',
			separator: true,
		},
		{ option: 'Question details' },
		{ option: 'Preview' },
		{ option: 'Print' },
	];

	return (
		<TableRow className='assignment-row'>
			<TableCell
				className='draggable'
				width='5%'
			>
				<IconButton>
					<DragIndicatorIcon />
				</IconButton>
			</TableCell>
			<TableCell
				scope='row'
				className='row-info'
			>
				<Typography
					variant='body3'
					mb={2}
				>
					{props.assignment.title}
				</Typography>
				<Typography
					variant='subtitle'
					color='text.secondary'
					className='assignment-metadata'
				>
					<span>{props.assignment.category}</span> |
					<span>{props.assignment.points} pts</span> |
					{props.assignment.tags.map((tagText) => (
						<span className='assignment-tag'>{tagText}</span>
					))}
				</Typography>
			</TableCell>

			<TableCell className='start-date'>
				<Typography
					variant='body2'
					fontWeight={600}
					display={'inline'}
					mr={2}
				>
					Start:
				</Typography>{' '}
				<Typography
					variant='body2'
					color='text.secondary'
					display={'inline'}
				>
					{props.assignment.startDate}
				</Typography>
			</TableCell>
			<TableCell className='row-date'>
				<Typography
					variant='body2'
					fontWeight={600}
					display={'inline'}
					mr={2}
				>
					Due:
				</Typography>{' '}
				<Typography
					variant='body2'
					color='text.secondary'
					display={'inline'}
				>
					{props.assignment.endDate}
				</Typography>
			</TableCell>
			<TableCell className='assign-status'>
				<Switch checked={props.assignment.status === 'Assigned'} />
			</TableCell>
			<TableCell
				className='assgn-options'
				width='7%'
			>
				<OptionsMenu
					title={props.assignment.title}
					options={menuOptions}
				/>
			</TableCell>
		</TableRow>
	);
};

export default ListTableItem;
