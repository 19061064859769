/** @format */

import pageCourseDetails from '../assets/images/page_course_details.png';

export default function CourseDetails(props) {
	return (
		<div className={`old-page ${props.mode}`}>
			<h2 className='page-title'>Course Details</h2>
			<img
				src={pageCourseDetails}
				alt=''
				style={{ display: 'block', margin: '0 auto' }}
			/>
		</div>
	);
}
