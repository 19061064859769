import { createSvgIcon } from '@mui/material';

const EditOutlined = createSvgIcon(
  <path
    d="M5.15376 19H6.39028L15.65 9.74034L14.4134 8.50379L5.15376 17.7635V19ZM18.8576 8.65574L15.4826 5.31157L16.7865 4.00774C17.0801 3.71416 17.4371 3.56737 17.8576 3.56737C18.2781 3.56737 18.6352 3.71416 18.9287 4.00774L20.146 5.22502C20.4396 5.5186 20.5915 5.87052 20.6018 6.28077C20.612 6.69102 20.4704 7.04293 20.1768 7.33652L18.8576 8.65574ZM17.773 9.75572L7.02873 20.5H3.65381V17.125L14.398 6.38077L17.773 9.75572ZM15.0269 9.11727L14.4134 8.50379L15.65 9.74034L15.0269 9.11727Z"
    fill="currentColor"
  />,
  'EditOutlined'
);

export default EditOutlined;
