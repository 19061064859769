/** @format */
import { Button, CardContent, Typography, Card } from '@mui/material';
import { Box } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PieChart } from '@mui/x-charts/PieChart';
import AssignmentsList from './AssignmentsList';
import BookCover from '../assets/images/collegealgebra.png';
import { useTheme } from '@mui/material/styles';

const Dashboard = (props) => {
	const theme = useTheme();

	return (
		<div className='main-content'>
			<h2 className='page-title'>Dashboard</h2>
			<div className='wrapper'>
				<div className='dashboard-2col'>
					<div className='col1'>
						<h3>Welcome back!</h3>
						<AssignmentsList />
					</div>
					<div className='col2'>
						<Card sx={{ mb: 9, mt: 4 }}>
							<CardContent
								sx={{
									pb: '1rem !important',
									position: 'relative',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<Typography variant={'subtitle4'}>Performance</Typography>
									<Button
										variant='link'
										size='small'
										sx={{ color: 'text.primary', px: 2 }}
										endIcon={<MoreVertIcon />}
									>
										Open gradebook
									</Button>
								</Box>
								<div className='piechart-label'>
									87% <span>average</span>
								</div>
								<PieChart
									slotProps={{
										legend: {
											position: {
												vertical: 'middle',
												horizontal: 'right',
											},
											itemGap: 12,
											itemMarkWidth: 12,
											itemMarkHeight: 12,
											borderRadius: 6,
											labelStyle: {
												fontSize: '0.75rem',
												fontWeight: 600,
											},
										},
									}}
									series={[
										{
											data: [
												{
													id: 0,
													value: 8,
													color: theme.palette.success.main,
													label: 'High performing',
												},
												{
													id: 1,
													value: 26,
													color: theme.palette.secondary.main,
													label: 'At expectation',
												},
												{
													id: 2,
													value: 2,
													color: theme.palette.error.main,
													label: 'Low performing',
												},
												{
													id: 3,
													value: 6,
													color: theme.palette.text.muted,
													label: 'Incomplete',
												},
											],
											innerRadius: 50,
											outerRadius: 65,
											paddingAngle: 0,
											cornerRadius: 5,
											startAngle: 0,
											endAngle: 360,
											cx: 65,
											cy: 65,
										},
									]}
									width={350}
									height={150}
								/>
							</CardContent>
						</Card>

						<Card>
							<CardContent
								sx={{
									display: 'flex',
									gap: '1rem',
									alignItems: 'center',
									pb: '1rem',
								}}
							>
								<img
									src={BookCover}
									alt=''
								/>
								<div>
									<Typography
										variant='subtitle4'
										component='h4'
										mb={2}
									>
										Anatomy & Physiology
									</Typography>
									<Typography
										variant='label3'
										component='p'
										mb={2}
									>
										7th edition
										<br />
										Elaine N Marieb, Katja Hoehn
									</Typography>
									<Button
										size='small'
										mt={2}
									>
										Open eTextbook
									</Button>
								</div>
							</CardContent>
						</Card>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
