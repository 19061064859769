import { createSvgIcon } from '@mui/material';

const ChevronDown = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.46967 6.96967C2.76256 6.67678 3.23744 6.67678 3.53033 6.96967L12 15.4393L20.4697 6.96967C20.7626 6.67678 21.2374 6.67678 21.5303 6.96967C21.8232 7.26256 21.8232 7.73744 21.5303 8.03033L12.5303 17.0303C12.2374 17.3232 11.7626 17.3232 11.4697 17.0303L2.46967 8.03033C2.17678 7.73744 2.17678 7.26256 2.46967 6.96967Z"
    fill="currentColor"
  />,
  'ChevronDown'
);

export default ChevronDown;
