import { createSvgIcon } from '@mui/material';

const Difficulty1 = createSvgIcon(
    <>
        <g clip-path="url(#clip0_6818_58684)">
            <circle cx="4" cy="4" r="4" fill="#007E53"/>
            <circle cx="16" cy="4" r="4" fill="currentColor"/>
            <circle cx="28" cy="4" r="4" fill="currentColor"/>
            <circle cx="40" cy="4" r="4" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_6818_58684">
                <rect width="44" height="8" fill="white"/>
            </clipPath>
        </defs>
    </>
    ,
  'Difficulty1'
);

export default Difficulty1;
