import { createSvgIcon } from '@mui/material';

const ChevronUp = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L21.5303 15.9697C21.8232 16.2626 21.8232 16.7374 21.5303 17.0303C21.2374 17.3232 20.7626 17.3232 20.4697 17.0303L12 8.56066L3.53033 17.0303C3.23744 17.3232 2.76256 17.3232 2.46967 17.0303C2.17678 16.7374 2.17678 16.2626 2.46967 15.9697L11.4697 6.96967Z"
    fill="currentColor"
  />,
  'ChevronUp'
);

export default ChevronUp;
