import { createSvgIcon } from '@mui/material';

const DeleteOutlined = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12.6364 4C13.3393 4 13.9091 4.59695 13.9091 5.33333H18.3636C18.7151 5.33333 19 5.63181 19 6C19 6.36819 18.7151 6.66667 18.3636 6.66667H17.7273V18C17.7273 19.1046 16.8725 20 15.8182 20H8.18182C7.12746 20 6.27273 19.1046 6.27273 18V6.66667H5.63636C5.28491 6.66667 5 6.36819 5 6C5 5.63181 5.28491 5.33333 5.63636 5.33333H10.0909C10.0909 4.59695 10.6607 4 11.3636 4H12.6364ZM16.4547 6.66667H7.54557V17.8519C7.54557 18.3019 7.83048 18.6667 8.18194 18.6667H15.8183C16.1698 18.6667 16.4547 18.3019 16.4547 17.8519V6.66667ZM10.0911 8V16.6667C10.0911 17.0349 9.80618 17.3333 9.45472 17.3333C9.10327 17.3333 8.81836 17.0349 8.81836 16.6667V8H10.0911ZM15.1814 8V16.6667C15.1814 17.0349 14.8965 17.3333 14.5451 17.3333C14.1936 17.3333 13.9087 17.0349 13.9087 16.6667V8H15.1814ZM12.6358 8V16.6667C12.6358 17.0349 12.3509 17.3333 11.9995 17.3333C11.648 17.3333 11.3631 17.0349 11.3631 16.6667V8H12.6358Z"
    fill="currentColor"
  />,
  'DeleteOutlined'
);

export default DeleteOutlined;
