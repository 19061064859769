/** @format */
import { useRef, useState } from 'react';
import NewAssignmentStartOptions from './NewAssignmentStartOptions';
import NewAssignmentAddContent from './NewAssignmentAddContent';
import units from '../assets/data/hwunitsflataandp.json';

const CreateAssignment = (props) => {
	const [step, setStep] = useState(props.step || 'start'); //Can be set to 'addcontent' to start from item selections page.
	const [selectedCount, setSelectedCount] = useState(0);
	const [inReview, setInReview] = useState(props.inReview || false);
	//const [reviewAssignment, setReviewAssignments] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const [assignmentUnits, setAssignmentUnits] = useState(units);
	const [selectedChapter, setSelectedChapter] = useState('empty');
	const [selectedSection, setSelectedSection] = useState('');
	const [selectedObjective, setSelectedObjective] = useState('');
	const [contentType, setContentType] = useState('all');
	const [selectedTime, setSelectedTime] = useState(0);
	const [selectedPoints, setSelectedPoints] = useState(0);
	const [sections, setSections] = useState([]);
	const [objectives, setObjectives] = useState([]);
	const [duplicateAlert, setDuplicateAlert] = useState(false);
	const [previewOpen, setPreviewOpen] = useState(false);

	const duplicateMesgRef = useRef(null);

	const addMore = () => {};
	const updateStep = (stepName) => {
		setStep(stepName);
	};

	const togglePreview = () => {
		setPreviewOpen(!previewOpen);
	};

	const showDuplicateAlert = (e) => {
		var mesgPosition = e.target.getBoundingClientRect();
		var xPos = mesgPosition.x - 250;
		var yPos = mesgPosition.y - 82;

		setDuplicateAlert(true);

		duplicateMesgRef.current.setAttribute(
			'style',
			`left: ${xPos}px; top: ${yPos}px`
		);
		setTimeout(
			(thisComponent) => {
				thisComponent.setState({
					duplicateAlert: false,
				});
			},
			3000,
			this
		);
	};

	const toggleSection = (key, keyID) => {
		var arr = assignmentUnits;
		arr.map((item) => {
			if (key === 'section') {
				if (item.chapter === selectedChapter && item.sectionId === keyID) {
					item.sectionOpen = !item.sectionOpen;
				}
			} else {
				if (item.chapter === selectedChapter && item.objectiveId === keyID) {
					item.objectiveOpen = !item.objectiveOpen;
				}
			}
			return item;
		});

		setAssignmentUnits(...arr);
	};

	const updateSelections = (e, questionId, deleteObjIndex) => {
		var currentCount = selectedCount,
			currentTime = selectedTime,
			currentPoints = selectedPoints;

		var arr = assignmentUnits.filter((a) => a.chapter === selectedChapter);
		var tempAssignmentItems = [];

		arr.map((question) => {
			if (question.id === questionId) {
				var newTime = parseFloat(question.time),
					newPoints = parseFloat(question.points);

				if (deleteObjIndex === undefined) {
					/* Check if duplicate message should be shown */
					if (question.selected) {
						showDuplicateAlert(e);
					}
					currentCount++;
					currentTime = Math.round((currentTime + newTime) * 100) / 100;
					currentPoints = currentPoints + newPoints;
					let itemToAdd = [];
					question.selected = true;
					itemToAdd.push(question);
					tempAssignmentItems = [...selectedItems, ...itemToAdd];
				} else {
					currentCount--;
					currentTime = currentTime - newTime;
					currentPoints = currentPoints - newPoints;
					/* Check for duplicates */
					let areThereDuplicates =
						selectedItems.filter((a) => a.title === question.title).length > 1;
					if (!areThereDuplicates) {
						question.selected = false;
					}
					tempAssignmentItems = selectedItems;
					tempAssignmentItems.splice(parseInt(deleteObjIndex), 1);
				}
			}
		});

		if (currentCount > 1) {
			setSelectedCount(currentCount);
			setSelectedTime(currentTime);
			setSelectedPoints(currentPoints);
			setSelectedItems([...tempAssignmentItems]);
			setPreviewOpen(true);
		} else if (currentCount === 0) {
			setSelectedCount(currentCount);
			setSelectedTime(currentTime);
			setSelectedPoints(currentPoints);
			setSelectedItems([...tempAssignmentItems]);
			setPreviewOpen(false);
		} else {
			setSelectedCount(currentCount);
			setSelectedTime(currentTime);
			setSelectedPoints(currentPoints);
			setSelectedItems([...tempAssignmentItems]);
			setPreviewOpen(true);
		}
	};

	const filterByChapter = (chapterNumber) => {
		var selectedChapterObj = units.filter((a) => a.chapter === chapterNumber),
			tempSections = [],
			tempObjectives = [];

		if (chapterNumber !== 'empty') {
			if (selectedChapterObj.length > 0) {
				selectedChapterObj.map((question, i) => {
					/* Add objective to list only if it's unique */
					if (
						tempObjectives.indexOf(question.objective) === -1 &&
						question.objective.length > 0
					) {
						tempObjectives.push(question.objective);
					}

					/* Add section to list only if it's unique */
					if (
						tempSections.indexOf(question.section) === -1 &&
						question.section.length > 0
					) {
						tempSections.push(question.section);
					}
				});
				setSelectedChapter(chapterNumber);
				setSelectedSection('all');
				setSelectedObjective('all');
				setSections([...tempSections]);
				setObjectives([...tempObjectives]);
			} else {
				setSelectedChapter(chapterNumber);
				setSelectedSection('all');
				setSelectedObjective('all');
			}
		}
	};

	const filterBySection = (sectionNumber) => {
		setSelectedSection(sectionNumber);
	};

	const filterByObjective = (objective) => {
		setSelectedObjective(objective);
	};

	//Function called in review when pools are created and removed
	const updateSelectionsWithPool = (data) => {
		setSelectedItems([...data]);
	};

	//Funtion to go to review assignments page
	const reviewAssignment = () => {
		setInReview(true);
		setPreviewOpen(false);
	}

	return (
		<>
			{step === 'start' ? (
				<NewAssignmentStartOptions
					assignmentType={props.assignmentType}
					assignmentName={props.assignmentName}
					setAssignmentName={props.setAssignmentName}
					selectedCount={selectedCount}
					inReview={inReview}
					createStep={step}
					reviewAssignment={reviewAssignment}
					addAssignment={props.addAssignment}
					updateStep={updateStep}
					addMore={addMore}
				/>
			) : step === 'addcontent' ? (
				<NewAssignmentAddContent
					assignmentType={props.assignmentType}
					assignmentName={props.assignmentName}
					setAssignmentName={props.setAssignmentName}
					selectedItems={selectedItems}
					assignmentUnits={assignmentUnits}
					selectedChapter={selectedChapter}
					selectedSection={selectedSection}
					selectedObjective={selectedObjective}
					contentType={contentType}
					updateSelections={updateSelections}
					selectedCount={selectedCount}
					selectedTime={selectedTime}
					selectedPoints={selectedPoints}
					inReview={inReview}
					filterByChapter={filterByChapter}
					filterBySection={filterBySection}
					filterByObjective={filterByObjective}
					followup={props.followup}
					sections={sections}
					objectives={objectives}
					toggleSection={toggleSection}
					reviewAssignment={reviewAssignment}
					addMore={addMore}
					createStep={step}
					addAssignment={props.addAssignment}
					updateSelectionsWithPool={updateSelectionsWithPool}
					duplicateAlert={duplicateAlert}
					previewOpen={previewOpen}
					togglePreview={togglePreview}
				/>
			) : (
				<></>
			)}
			{duplicateAlert && (
				<div
					className='duplicate-message'
					ref={duplicateMesgRef}
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='40'
						height='40'
						viewBox='0 0 40 40'
						fill='none'
					>
						<rect
							width='40'
							height='40'
							rx='20'
							fill='#4CAF50'
							fillOpacity='0.5'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M32 20C32 26.6274 26.6274 32 20 32C13.3726 32 8 26.6274 8 20C8 13.3726 13.3726 8 20 8C26.6274 8 32 13.3726 32 20ZM20.75 14.75C20.75 14.3358 20.4142 14 20 14C19.5858 14 19.25 14.3358 19.25 14.75V19.25H14.75C14.3358 19.25 14 19.5858 14 20C14 20.4142 14.3358 20.75 14.75 20.75H19.25V25.25C19.25 25.6642 19.5858 26 20 26C20.4142 26 20.75 25.6642 20.75 25.25V20.75H25.25C25.6642 20.75 26 20.4142 26 20C26 19.5858 25.6642 19.25 25.25 19.25H20.75V14.75Z'
							fill='#388539'
						/>
					</svg>
					<p>Duplicate item added to assignment</p>
				</div>
			)}
		</>
	);
};

export default CreateAssignment;
