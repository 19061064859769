/** @format */
import { useState } from 'react';
import { Button, ToggleButtonGroup, ToggleButton } from '@mui/material';
import ListView from './ListView';
import data from '../assets/data/assignments.json';

function Assignments() {
	const [assignments] = useState(data);
	const [startDate] = useState(new Date());

	return (
		<div className='wrapper'>
			<h2>Assignments</h2>
			<div className='section-header'>
				<div className='search-assignments'>
					<label
						htmlFor='search-assignments'
						className='gr-label'
						aria-label='Search assignments'
					>
						<span class='material-icons'>search</span>
					</label>
					<select
						label='Assignment type'
						value={1}
					>
						<option value={1}>All assignment types</option>
					</select>
				</div>
				<div className='actions'>
					<Button
						href='assignments/create-assignment'
						variant='outlined'
						color='primary'
					>
						Create new
					</Button>

					<ToggleButtonGroup
						exclusive
						value='list'
					>
						<ToggleButton
							value='list'
							aria-label='list view'
							variant='text'
						>
							<span className='material-icons'>format_list_bulleted</span>
						</ToggleButton>
						<ToggleButton
							value='calendar'
							aria-label='calendar view'
							variant='text'
						>
							<span class='material-icons'>calendar_month</span>
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
			</div>

			<ListView
				assignments={assignments}
				startDate={startDate}
			/>
		</div>
	);
}

export default Assignments;
