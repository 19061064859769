/** @format */

// State held component not using Redux
import { useState } from 'react';
import {
	TableContainer,
	Table,
	TableBody,
	Paper,
	Card,
	CardContent,
} from '@mui/material';
import ListTableItem from './ListTableItem';

const ListView = (props) => {
	const [maxCount] = useState(props.assignments.length);

	return (
		<Card className='table-wrapper'>
			<CardContent sx={{ p: '0 !important' }}>
				<TableContainer component={Paper}>
					<Table>
						<TableBody>
							{props.assignments.map((assignment, index) => {
								return (
									<ListTableItem
										assignment={assignment}
										maxCount={maxCount}
										key={`assignment-item-${index}`}
									/>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
		</Card>
	);
};

export default ListView;
