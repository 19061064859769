/** @format */

// State held component not using Redux
import React, { Component } from 'react';
import OptionsMenu from '../shared/OptionsMenu';
import DifficultyLevel from './DifficultyLevel';
import { v4 as uuid } from 'uuid';

class Pool extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: this.props.item.expanded,
		};

		this.menuOptions = [
			{ option: 'Edit' },
			{
				option: 'Duplicate',
				separator: true,
			},
			{
				option: 'Delete',
				separator: true,
			},
			{
				option: 'Item Settings',
				separator: true,
			},
			{
				option: 'Preview',
			},
			{
				option: 'Print',
			},
		];

		this.togglePool = this.togglePool.bind(this);
	}

	togglePool() {
		this.setState({
			expanded: !this.state.expanded,
		});
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {}

	render() {
		return (
			<li
				key={uuid()}
				className={`pool ${this.state.expanded ? 'expanded' : ''}`}
			>
				<div className='item-inner pool-top'>
					<button className='gr-brn icon-btn-18'>
						<svg
							focusable='false'
							className='icon-18'
							aria-hidden='true'
						>
							<use
								xmlnsXlink='http://www.w3.org/1999/xlink'
								xlinkHref='#delete-18'
							></use>
						</svg>
					</button>

					<div>{this.props.item.name}</div>

					<button
						className='pool-toggle'
						onClick={this.togglePool}
					>
						<span className='circle'>
							{this.state.expanded ? (
								<svg
									focusable='false'
									className='icon-18'
									aria-hidden='true'
								>
									<use
										xmlnsXlink='http://www.w3.org/1999/xlink'
										xlinkHref='#pool-expand'
									></use>
								</svg>
							) : (
								<svg
									focusable='false'
									className='icon-18'
									aria-hidden='true'
								>
									<use
										xmlnsXlink='http://www.w3.org/1999/xlink'
										xlinkHref='#pool-collapse'
									></use>
								</svg>
							)}
						</span>
					</button>
				</div>
				{this.state.expanded && (
					<ul>
						{this.props.item.children.map((child) => {
							return (
								<li key={uuid()}>
									<div className='item-inner'>
										<div></div>

										<a
											href='!#'
											target='_blank'
											onClick={(e) => e.preventDefault()}
										>
											<span
												onClick={localStorage.setItem(
													'qName',
													this.props.item.name
												)}
											>
												{this.props.item.name}
											</span>
										</a>

										<DifficultyLevel difficulty={child.difficulty} />

										<OptionsMenu
											title={child.title}
											options={child.menuOptions}
											menuNodeKey={child.id}
										/>
									</div>
								</li>
							);
						})}
					</ul>
				)}
			</li>
		);
	}
}

export default Pool;
