import { createSvgIcon } from '@mui/material';

const Difficulty2 = createSvgIcon(
    <svg viewBox="0 0 44 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="4" r="4" fill="#857100"/>
        <circle cx="16" cy="4" r="4" fill="#857100"/>
        <circle cx="28" cy="4" r="4" fill="currentColor"/>
        <circle cx="40" cy="4" r="4" fill="currentColor"/>
    </svg>
    ,
  'Difficulty2'
);

export default Difficulty2;
