/** @format */

// State held component not using Redux
import React, { Component, useEffect, useState } from 'react';
import OptionsMenu from '../shared/OptionsMenu';
import Pool from './Pool';
import { v4 as uuid } from 'uuid';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton, Typography } from '@mui/material';

const NewAssignmentSelections = (props) => {
	const [showing, setShowing] = useState(false);

	const menuOptions = [
		{ option: 'Edit' },
		{
			option: 'Duplicate',
			separator: true,
		},
		{
			option: 'Delete',
			separator: true,
		},
		{
			option: 'Item Settings',
			separator: true,
		},
		{
			option: 'Preview',
		},
		{
			option: 'Print',
		},
	];

	useEffect(() => {
		setTimeout(() => {
			setShowing(true);
		}, 100);
	}, []);

	return (
		<aside className='assignment-selections'>
			<header>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<IconButton onClick={props.togglePreview}>
						<DoubleArrowOutlinedIcon />
					</IconButton>
					<h3>My Selections</h3>
				</div>
				<IconButton>
					<SettingsOutlinedIcon />
				</IconButton>
			</header>
			{props.selectedCount > 0 && (
				<div className='selection-summary'>
					<div className='assignment-cart__items'>
						<span className='count'>{props.selectedCount} items</span>
					</div>

					{props.assignmentType === 'Homework' && (
						<div className='assignment-cart__points'>
							<span className='count'>{props.selectedPoints}</span>{' '}
							<strong>pts</strong>
						</div>
					)}
				</div>
			)}

			<ul className='items-list'>
				{props.selectedItems.map((item, i) => {
					return item.children && item.children.length > 0 ? (
						<Pool item={item} />
					) : (
						<li key={uuid()}>
							<div className='item-inner'>
								<IconButton
									onClick={(e) => props.updateSelections(e, item.id, i)}
								>
									<DeleteOutlineOutlinedIcon />
								</IconButton>

								<Typography
									onClick={localStorage.setItem('qName', item.name)}
									variant='caption'
								>
									{item.name}
								</Typography>

								<OptionsMenu
									title={item.title}
									options={menuOptions}
									menuNodeKey={item.id}
								/>
							</div>
						</li>
					);
				})}
			</ul>
		</aside>
	);
};

export default NewAssignmentSelections;
