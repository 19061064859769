import { createSvgIcon } from '@mui/material';

const DragIndicator = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9.3 18.4C10.018 18.4 10.6 18.982 10.6 19.7C10.6 20.418 10.018 21 9.3 21C8.58203 21 8 20.418 8 19.7C8 18.982 8.58203 18.4 9.3 18.4ZM14.7 18.4C15.418 18.4 16 18.982 16 19.7C16 20.418 15.418 21 14.7 21C13.982 21 13.4 20.418 13.4 19.7C13.4 18.982 13.982 18.4 14.7 18.4ZM9.3 13.2667C10.018 13.2667 10.6 13.8487 10.6 14.5667C10.6 15.2846 10.018 15.8667 9.3 15.8667C8.58203 15.8667 8 15.2846 8 14.5667C8 13.8487 8.58203 13.2667 9.3 13.2667ZM14.7 13.2667C15.418 13.2667 16 13.8487 16 14.5667C16 15.2846 15.418 15.8667 14.7 15.8667C13.982 15.8667 13.4 15.2846 13.4 14.5667C13.4 13.8487 13.982 13.2667 14.7 13.2667ZM9.3 8.13333C10.018 8.13333 10.6 8.71536 10.6 9.43333C10.6 10.1513 10.018 10.7333 9.3 10.7333C8.58203 10.7333 8 10.1513 8 9.43333C8 8.71536 8.58203 8.13333 9.3 8.13333ZM14.7 8.13333C15.418 8.13333 16 8.71536 16 9.43333C16 10.1513 15.418 10.7333 14.7 10.7333C13.982 10.7333 13.4 10.1513 13.4 9.43333C13.4 8.71536 13.982 8.13333 14.7 8.13333ZM9.3 3C10.018 3 10.6 3.58203 10.6 4.3C10.6 5.01797 10.018 5.6 9.3 5.6C8.58203 5.6 8 5.01797 8 4.3C8 3.58203 8.58203 3 9.3 3ZM14.7 3C15.418 3 16 3.58203 16 4.3C16 5.01797 15.418 5.6 14.7 5.6C13.982 5.6 13.4 5.01797 13.4 4.3C13.4 3.58203 13.982 3 14.7 3Z"
    fill="currentColor"
  />,
  'DragIndicator'
);

export default DragIndicator;
