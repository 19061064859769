/** @format */

// State held component not using Redux
import { useState } from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	TableContainer,
	Table,
	TableBody,
	Paper,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import QuestionItem from './QuestionItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';

const ChapterSection = (props) => {
	const [detailsOpen, setDetailsOpen] = useState(
		props.data.sectionOpen === undefined ? false : props.data.sectionOpen
	);

	return (
		<Accordion
			sx={{ my: 2 }}
			expanded={detailsOpen}
			onChange={(e, expanded) => {
				setDetailsOpen(expanded);
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls={`section${props.sectionIndex}-content`}
				id={`section${props.sectionIndex}-header`}
				sx={{ py: 1, px: 8 }}
			>
				<Typography variant='subtitle4'>{props.group}</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{ p: 0 }}
				id={`section${props.sectionIndex}-content`}
			>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell
									scope='col'
									width='5%'
								></TableCell>
								<TableCell>
									<span className='table-header'>
										ID/Title <UnfoldMoreOutlinedIcon />
									</span>
								</TableCell>
								<TableCell>
									<span className='table-header'>
										Objective <UnfoldMoreOutlinedIcon />
									</span>
								</TableCell>
								<TableCell>
									<span className='table-header'>
										Type <UnfoldMoreOutlinedIcon />
									</span>
								</TableCell>
								<TableCell>
									<span className='table-header'>
										Difficulty
										<UnfoldMoreOutlinedIcon />
									</span>
								</TableCell>
								<TableCell>
									<span className='table-header'>
										Est. time <UnfoldMoreOutlinedIcon />
									</span>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.data.map((question, i) => {
								return (
									<QuestionItem
										question={question}
										key={`qitem${props.sectionIndex}${i}`}
										updateSelections={props.updateSelections}
									/>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<table className='question-table'>
					<tbody>
						{props.data.map((question, i) => {
							return <></>;
						})}
					</tbody>
				</table>
			</AccordionDetails>
		</Accordion>
	);
};

export default ChapterSection;
