/** @format */

// State held component not using Redux
import { useState } from 'react';
import FeaturedContentDetails from './FeaturedContentDetails';
import Filters from './Filters';
import EmptyState from './EmptyState';
import chapters from '../assets/data/a&p.json';
import { Typography } from '@mui/material';

const NewAssignmentLibrary = (props) => {
	const [searchOpen, setSearchOpen] = useState(false);
	const [bookDDOpen, setBookDDOpen] = useState(false);
	const [selectedCountUpdated, setSelectedCountUpdated] = useState(false);
	const [filterOpen, setFilterOpen] = useState(false);
	const [typeFilterOpen, setTypeFilterOpen] = useState(false);

	const closeFilterModal = () => {
		setTypeFilterOpen(false);
		setFilterOpen(false);
	};

	const toggleTypeFilterModal = () => {
		setTypeFilterOpen(!typeFilterOpen);
		setFilterOpen(false);
	};

	const toggleFilterModal = () => {
		setFilterOpen(!filterOpen);
		setTypeFilterOpen(false);
	};

	const toggleSearch = () => {
		setSearchOpen(!searchOpen);
		setBookDDOpen(false);
	};

	const toggleBookDD = () => {
		setSearchOpen(false);
		setBookDDOpen(!bookDDOpen);
	};

	return (
		<div className='assignment-creation-content'>
			<section className='search-filter-toggle'>
				<Typography
					variant='subtitle2'
					component='h3'
					sx={{ mb: 2 }}
				>
					Select Content
				</Typography>

				<Filters
					filterByChapter={props.filterByChapter}
					chapters={chapters}
					selectedChapter={props.selectedChapter}
					filterBySection={props.filterBySection}
					sections={props.sections}
					selectedSection={props.selectedSection}
					toggleFilterModal={toggleFilterModal}
					closeFilterModal={closeFilterModal}
					filterOpen={filterOpen}
					objectives={props.objectives}
					filterByObjective={props.filterByObjective}
					selectedObjective={props.selectedObjective}
					outcomes={props.standardsView}
				/>
			</section>

			{props.selectedChapter === 'empty' ? (
				<EmptyState
					assignmentType={props.assignmentType}
					standardsView={props.standardsView}
				/>
			) : (
				<FeaturedContentDetails
					contentType={props.contentType}
					selectedChapter={props.selectedChapter}
					selectedSection={props.selectedSection}
					selectedObjective={props.selectedObjective}
					updateSelections={props.updateSelections}
					assignmentUnits={props.assignmentUnits}
					toggleSection={props.toggleSection}
					previewOpen={props.previewOpen}
					sections={props.sections}
					objectives={props.objectives}
					standardsView={props.standardsView}
					duplicateAlert={props.duplicateAlert}
					togglePreview={props.togglePreview}
				/>
			)}
		</div>
	);
};

export default NewAssignmentLibrary;
