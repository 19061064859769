/** @format */

// State held component not using Redux
import { useState } from 'react';
import NewAssignmentLibrary from './NewAssignmentLibrary';
import NewAssignmentSelections from './NewAssignmentSelections';
import CreateAssignmentHeader from './CreateAssignmentHeader';
import ReviewListHW from './ReviewListHW';

const NewAssignmentAddContent = (props) => {
	const [standardsView, setStandardsView] = useState(false);
	const toggleStandardsMode = () => {
		setStandardsView(!standardsView);
	};

	return (
		<section className='create-assignment'>
			<CreateAssignmentHeader
				assignmentType={props.assignmentType}
				assignmentName={props.assignmentName}
				setAssignmentName={props.setAssignmentName}
				previewOpen={props.previewOpen}
				togglePreview={props.togglePreview}
				selectedCount={props.selectedCount}
				inReview={props.inReview}
				reviewAssignment={props.reviewAssignment}
				createStep={props.createStep}
				addAssignment={props.addAssignment}
			/>
			<div
				className={`add-content-body ${
					props.previewOpen ? '' : 'preview-closed'
				} ${props.inReview ? 'in-review' : ''}`}
			>
				<NewAssignmentLibrary
					assignmentUnits={props.assignmentUnits}
					searchKeyword={props.searchKeyword}
					selectedChapter={props.selectedChapter}
					selectedSection={props.selectedSection}
					selectedObjective={props.selectedObjective}
					contentType={props.contentType}
					updateSelections={props.updateSelections}
					filterByChapter={props.filterByChapter}
					filterBySection={props.filterBySection}
					filterByObjective={props.filterByObjective}
					sections={props.sections}
					objectives={props.objectives}
					assignmentType={props.assignmentType}
					toggleSection={props.toggleSection}
					previewOpen={props.previewOpen}
					createStep={props.createStep}
					inReview={props.inReview}
					selectedCount={props.selectedCount}
					togglePreview={props.togglePreview}
					toggleStandardsMode={toggleStandardsMode}
					standardsView={standardsView}
					duplicateAlert={props.duplicateAlert}
				/>
				{props.inReview ? (
					<ReviewListHW
						data={props.selectedItems}
						selectedCount={props.selectedCount}
						selectedPoints={props.selectedPoints}
						addMore={props.addMore}
						toggleSettings={props.toggleSettings}
						updateSelectionsWithPool={props.updateSelectionsWithPool}
						toggleStandardsMode={toggleStandardsMode}
						standardsView={standardsView}
					/>
				) : (
					<NewAssignmentSelections
						selectedItems={props.selectedItems}
						togglePreview={props.togglePreview}
						selectedCount={props.selectedCount}
						selectedTime={props.selectedTime}
						selectedPoints={props.selectedPoints}
						assignmentType={props.assignmentType}
						toggleSettings={props.toggleSettings}
						updateSelections={props.updateSelections}
					/>
				)}
			</div>
		</section>
	);
};
export default NewAssignmentAddContent;
