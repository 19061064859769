/** @format */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isDescendant } from './utils/tree-data-utils';
import QuestionOutlined from '../Icons/QuestionOutlined';
import Pool from '../Icons/Pool';
import DragIndicator from '../Icons/DragIndicator';
import classnames from './utils/classnames';
import './node-renderer-custom.css';
import { Card } from '@mui/material';
import ChevronDown from '../Icons/ChevronDown';
import ChevronUp from '../Icons/ChevronUp';

class CustomNodeContentRenderer extends Component {
	componentDidUpdate(prevProps) {}

	render() {
		const {
			scaffoldBlockPxWidth,
			toggleChildrenVisibility,
			connectDragPreview,
			connectDragSource,
			isDragging,
			canDrop,
			canDrag,
			node,
			title,
			subtitle,
			draggedNode,
			path,
			treeIndex,
			isSearchMatch,
			isSearchFocus,
			buttons,
			checkbox,
			dropdown,
			className,
			style,
			didDrop,
			treeId,
			isOver, // Not needed, but preserved for other renderers
			parentNode, // Needed for dndManager
			rowDirection,
			...otherProps
		} = this.props;
		const nodeTitle = node.name;
		const nodeSubtitle = subtitle || node.subtitle;
		const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;

		let handle;
		if (canDrag) {
			if (typeof node.children === 'function' && node.expanded) {
				// Show a loading symbol on the handle when the children are expanded
				//  and yet still defined by a function (a callback to fetch the children)
				handle = (
					<div className='rst__loadingHandle'>
						<div className='rst__loadingCircle'>
							{[...new Array(12)].map((_, index) => (
								<div
									// eslint-disable-next-line react/no-array-index-key
									key={index}
									className={classnames(
										'rst__loadingCirclePoint',
										rowDirectionClass
									)}
								/>
							))}
						</div>
					</div>
				);
			} else {
				// Show the handle used to initiate a drag-and-drop
				handle = connectDragSource(
					<div className='rst__moveHandle'>
						<span className='circle'>
							<DragIndicator />
						</span>
					</div>,
					{
						dropEffect: 'copy',
					}
				);
			}
		}

		const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
		const isLandingPadActive = !didDrop && isDragging;
		let isInPool = path.length > 1; //Check if a node has a parent

		return (
			<Card
				style={{ height: '100%' }}
				{...otherProps}
				elevation='0'
				className={classnames(className, node.expanded ? '' : 'collapsed')}
			>
				<div
					className={classnames(
						'rst__rowWrapper',
						rowDirectionClass,
						node.children &&
							(node.children.length > 0 ||
								typeof node.children === 'function') &&
							'pool-top',
						isInPool && 'pool-child'
					)}
				>
					{/* Set the row preview to be used during drag and drop */}
					{connectDragPreview(
						<div
							className={classnames(
								'rst__row',
								node.selectedToRemove && 'selected',
								node.isParentSelected && 'parent-selected',
								node.expanded && 'expanded',
								isLandingPadActive && 'rst__rowLandingPad',
								isLandingPadActive && !canDrop && 'rst__rowCancelPad',
								isSearchMatch && 'rst__rowSearchMatch',
								isSearchFocus && 'rst__rowSearchFocus',
								rowDirectionClass,
								'MuiCard-root MuiPaper-root'
							)}
							style={{
								opacity: isDraggedDescendant ? 0.5 : 1,
								...style,
							}}
							role='button'
							onClick={(e) => this.props.selectitem(e, node.id)}
						>
							<div className='order'>{handle}</div>
							<div
								className={classnames(
									'rst__rowContents',
									!canDrag && 'rst__rowContentsDragDisabled',
									rowDirectionClass
								)}
							>
								{node.children &&
								(node.children.length > 0 ||
									typeof node.children === 'function') ? (
									<div className='pool-name'>
										<Pool /> {node.name}
										<span>{node.children.length} items</span>
									</div>
								) : (
									<div className='type'>
										<div className='icon-container'>
											<QuestionOutlined />
										</div>
									</div>
								)}
								<div className={classnames('rst__rowLabel', rowDirectionClass)}>
									<span
										className={classnames(
											'rst__rowTitle',
											node.subtitle && 'rst__rowTitleWithSubtitle'
										)}
									>
										{node.children &&
										(node.children.length > 0 ||
											typeof node.children === 'function') ? (
											<></>
										) : (
											<div className='item-details'>
												<span className='itemId'>{node.itemId}</span>
												<span className='itemName'>{node.name}</span>
												<div>
													{node.type} | <strong>Est time:</strong> {node.time}{' '}
													min
												</div>
											</div>
										)}
									</span>
								</div>
								<div className='rst__rowToolbar'>
									{buttons.map((btn, index) => (
										<div
											key={index} // eslint-disable-line react/no-array-index-key
											className='rst__toolbarButton'
										>
											{btn}
										</div>
									))}

									{toggleChildrenVisibility &&
										node.children &&
										(node.children.length > 0 ||
											typeof node.children === 'function') && (
											<div
												className='pool-toggle'
												onClick={(e) => {
													console.log('Clicked', node);
													e.stopPropagation();
													toggleChildrenVisibility({
														node,
														path,
														treeIndex,
													});
												}}
											>
												<span className='circle'>
													{node.expanded ? <ChevronDown /> : <ChevronUp />}
												</span>
											</div>
										)}
								</div>
							</div>
						</div>
					)}
				</div>
			</Card>
		);
	}
}

CustomNodeContentRenderer.defaultProps = {
	isSearchMatch: false,
	isSearchFocus: false,
	canDrag: false,
	toggleChildrenVisibility: null,
	buttons: [],
	checkbox: [],
	dropdown: [],
	className: '',
	style: {},
	parentNode: null,
	draggedNode: null,
	canDrop: false,
	title: null,
	subtitle: null,
	rowDirection: 'ltr',
};

CustomNodeContentRenderer.propTypes = {
	node: PropTypes.shape({}).isRequired,
	title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	path: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	).isRequired,
	treeIndex: PropTypes.number.isRequired,
	treeId: PropTypes.string.isRequired,
	isSearchMatch: PropTypes.bool,
	isSearchFocus: PropTypes.bool,
	canDrag: PropTypes.bool,
	scaffoldBlockPxWidth: PropTypes.number.isRequired,
	toggleChildrenVisibility: PropTypes.func,
	buttons: PropTypes.arrayOf(PropTypes.node),
	checkbox: PropTypes.arrayOf(PropTypes.node),
	className: PropTypes.string,
	style: PropTypes.shape({}),

	// Drag and drop API functions
	// Drag source
	connectDragPreview: PropTypes.func.isRequired,
	connectDragSource: PropTypes.func.isRequired,
	parentNode: PropTypes.shape({}), // Needed for dndManager
	isDragging: PropTypes.bool.isRequired,
	didDrop: PropTypes.bool.isRequired,
	draggedNode: PropTypes.shape({}),
	// Drop target
	isOver: PropTypes.bool.isRequired,
	canDrop: PropTypes.bool,

	// rtl support
	rowDirection: PropTypes.string,
};

export default CustomNodeContentRenderer;
