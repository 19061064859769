/** @format */

import pageDocumentSharing from '../assets/images/page_document_sharing.png';

export default function DocumentSharing(props) {
	return (
		<div className={`old-page ${props.mode}`}>
			<h2 className='page-title'>Document Sharing</h2>
			<img
				src={pageDocumentSharing}
				alt=''
				style={{ display: 'block', margin: '0 auto' }}
			/>
		</div>
	);
}
