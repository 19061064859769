/** @format */

import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import difficulty1 from '../assets/images/difficulty_1.svg';
import difficulty2 from '../assets/images/difficulty_2.svg';
import difficulty3 from '../assets/images/difficulty_3.svg';
import difficulty4 from '../assets/images/difficulty_4.svg';

const QuestionItem = (props) => {
	return (
		<TableRow>
			<TableCell width='5%'>
				<IconButton
					className={`add-item ${props.question.selected ? 'selected' : ''}`}
					id={props.question.id}
					onClick={(e) => props.updateSelections(e, props.question.id)}
				>
					<AddCircleOutlineOutlinedIcon />
				</IconButton>
			</TableCell>
			<TableCell>{props.question.name}</TableCell>
			<TableCell>{props.question.objective}</TableCell>
			<TableCell>
				<div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
					{props.question.answerType === 'Question' ? (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='22'
							viewBox='0 0 24 22'
							fill='none'
						>
							<path
								d='M15.5208 2.60239H18.32C19.4572 2.60239 20.4194 3.56461 20.4194 4.70179V7.50099L22.3876 9.51292C23.2186 10.3439 23.2186 11.6561 22.3876 12.4871L20.4194 14.499V17.2982C20.4194 18.4791 19.4572 19.3976 18.32 19.3976H15.4771L13.4652 21.4095C12.6779 22.1968 11.322 22.1968 10.5347 21.4095L8.52281 19.3976H5.72361C4.54269 19.3976 3.6242 18.4791 3.6242 17.2982V14.499L1.61227 12.4871C0.78126 11.6561 0.78126 10.3439 1.61227 9.51292L3.6242 7.50099V4.70179C3.6242 3.56461 4.54269 2.60239 5.72361 2.60239H8.47907L10.5347 0.590457C11.322 -0.196819 12.6779 -0.196819 13.4652 0.590457L15.5208 2.60239ZM5.72361 4.70179V7.50099C5.72361 8.06958 5.46118 8.59443 5.06754 8.98807L3.09935 11L5.06754 13.0119C5.46118 13.4056 5.72361 13.9304 5.72361 14.499V17.2982H8.52281C9.0914 17.2982 9.61625 17.5606 10.0099 17.9543L12.0218 19.9225L13.99 17.9543C14.3836 17.5606 14.9085 17.2982 15.4771 17.2982H18.32V14.499C18.32 13.9304 18.5387 13.4056 18.9324 13.0119L20.9005 11L18.9324 8.98807C18.5387 8.59443 18.32 8.06958 18.32 7.50099V4.70179H15.5208C14.9522 4.70179 14.4274 4.4831 14.0337 4.08946L11.9781 2.07753L9.96615 4.08946C9.57251 4.4831 9.04766 4.70179 8.47907 4.70179H5.72361Z'
								fill='#585858'
							/>
							<path
								d='M11.3954 11.1842C11.8184 10.4526 12.6314 10.0211 13.1039 9.37368C13.6037 8.69474 13.3236 7.42632 11.9063 7.42632C10.978 7.42632 10.522 8.1 10.3297 8.65789L8.90698 8.08421C9.29701 6.96316 10.3572 6 11.9008 6C13.1917 6 14.0762 6.56316 14.5266 7.26842C14.9111 7.87368 15.1364 9.00526 14.5431 9.84737C13.8839 10.7789 13.2522 11.0632 12.9116 11.6632C12.7743 11.9053 12.7193 12.0632 12.7193 12.8421H11.1318C11.1263 12.4316 11.0603 11.7632 11.3954 11.1842ZM13.005 14.9474C13.005 15.5263 12.5106 16 11.9063 16C11.3021 16 10.8077 15.5263 10.8077 14.9474C10.8077 14.3684 11.3021 13.8947 11.9063 13.8947C12.5106 13.8947 13.005 14.3684 13.005 14.9474Z'
								fill='#585858'
							/>
						</svg>
					) : props.question.answerType === 'Reading' ? (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='18'
							viewBox='0 0 24 18'
							fill='none'
						>
							<path
								d='M21.8438 1.20858C20.0078 0.544515 18.2891 0.232015 16.7656 0.271077C14.5 0.271077 12.7812 0.93514 12 1.32577C11.1797 0.93514 9.46094 0.271077 7.19531 0.271077C5.63281 0.232015 3.95312 0.544515 2.11719 1.20858C1.29688 1.52108 0.75 2.30233 0.75 3.20076V14.607C0.75 15.232 1.02344 15.857 1.53125 16.2476C2.07812 16.6383 2.78125 16.7945 3.44531 16.5992C8.05469 15.3492 11.3359 17.4976 11.4531 17.5758C11.6094 17.693 11.8047 17.732 11.9609 17.732C12.0781 17.732 12.3125 17.693 12.4688 17.5758C12.625 17.4976 15.9062 15.3492 20.5156 16.5992C21.1406 16.7945 21.8438 16.6383 22.3906 16.2476C22.9375 15.857 23.25 15.232 23.25 14.607V3.20076C23.25 2.30233 22.6641 1.52108 21.8438 1.20858ZM11.0625 15.2711C9.96875 14.8414 8.32812 14.3336 6.29688 14.3336C5.28125 14.3336 4.14844 14.4508 2.9375 14.8023C2.82031 14.8414 2.70312 14.7633 2.70312 14.7633C2.625 14.6851 2.625 14.607 2.625 14.607V3.20076C2.625 3.08358 2.66406 3.00545 2.74219 2.96639C4.34375 2.41951 5.86719 2.10702 7.15625 2.14608C8.99219 2.14608 10.3594 2.65389 11.0625 2.96639V15.2711ZM21.375 14.607C21.375 14.607 21.375 14.6851 21.2969 14.7633C21.2578 14.7633 21.1406 14.8414 21.0234 14.8023C17.4688 13.8258 14.5781 14.607 12.8984 15.2711V2.96639C13.6016 2.65389 14.9688 2.14608 16.8047 2.14608C18.0938 2.10702 19.6172 2.41951 21.2188 2.96639C21.2969 3.00545 21.375 3.08358 21.375 3.20076V14.607Z'
								fill='#585858'
							/>
						</svg>
					) : props.question.answerType === 'Activity' ? (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='20'
							viewBox='0 0 20 20'
							fill='none'
						>
							<path
								d='M16.0547 8.125C15.8594 8.51562 15.4297 8.75 15 8.75H7.5C7.03125 8.75 6.60156 8.51562 6.40625 8.125C6.17188 7.73438 6.17188 7.26562 6.40625 6.875L10.1562 0.625C10.3906 0.234375 10.7812 0 11.25 0C11.6797 0 12.0703 0.234375 12.3047 0.625L16.0547 6.875C16.2891 7.26562 16.2891 7.73438 16.0547 8.125ZM11.25 2.5L8.59375 6.875H13.8672L11.25 2.5ZM18.4375 10.625C19.2969 10.625 20 11.3281 20 12.1875V17.8125C20 18.7109 19.2969 19.375 18.4375 19.375H12.8125C11.9141 19.375 11.25 18.7109 11.25 17.8125V12.1875C11.25 11.3281 11.9141 10.625 12.8125 10.625H18.4375ZM18.125 12.5H13.125V17.5H18.125V12.5ZM10 15C10 17.7734 7.73438 20 5 20C2.22656 20 0 17.7734 0 15C0 12.2656 2.22656 10 5 10C7.73438 10 10 12.2656 10 15ZM5 11.875C3.24219 11.875 1.875 13.2812 1.875 15C1.875 16.7578 3.24219 18.125 5 18.125C6.71875 18.125 8.125 16.7578 8.125 15C8.125 13.2812 6.71875 11.875 5 11.875Z'
								fill='#585858'
							/>
						</svg>
					) : (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='20'
							viewBox='0 0 20 20'
							fill='none'
						>
							<path
								d='M7.34375 5.78125C7.61719 5.58594 8.00781 5.58594 8.28125 5.78125L13.9062 9.21875C14.1797 9.375 14.375 9.6875 14.375 10C14.375 10.3516 14.1797 10.6641 13.9062 10.8203L8.28125 14.2578C8.00781 14.4141 7.61719 14.4531 7.34375 14.2578C7.03125 14.1016 6.875 13.7891 6.875 13.4375V6.5625C6.875 6.25 7.03125 5.9375 7.34375 5.78125ZM20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0C15.5078 0 20 4.49219 20 10ZM10 1.875C5.50781 1.875 1.875 5.54688 1.875 10C1.875 14.4922 5.50781 18.125 10 18.125C14.4531 18.125 18.125 14.4922 18.125 10C18.125 5.54688 14.4531 1.875 10 1.875Z'
								fill='#585858'
							/>
						</svg>
					)}{' '}
					{props.question.answerType}
				</div>
			</TableCell>
			<TableCell>
				{props.question.difficulty === '1' ? (
					<>
						<img
							src={difficulty1}
							alt=''
						/>{' '}
						<span className='difficulty-label'>Easy</span>
					</>
				) : props.question.difficulty === '2' ? (
					<>
						<img
							src={difficulty2}
							alt=''
						/>
						<span className='difficulty-label'>Moderate</span>
					</>
				) : (
					<>
						<img
							src={difficulty3}
							alt=''
						/>
						<span className='difficulty-label'>Hard</span>
					</>
				)}
			</TableCell>
			<TableCell>{props.question.time}</TableCell>
		</TableRow>
	);
};

export default QuestionItem;
