/** @format */
import { AppBar, Toolbar, Typography } from '@mui/material';
import UserMenu from './UserMenu';

const Header = (props) => {
	return (
		<AppBar
			position='static'
			color='transparent'
			className='app-header'
		>
			<Toolbar>
				<Typography
					variant='h6'
					component='h1'
					sx={{ flexGrow: 1 }}
				>
					Mastering Anatomy &amp; Physiology
				</Typography>
				<UserMenu />
			</Toolbar>
		</AppBar>
	);
};

export default Header;
