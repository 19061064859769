/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/prefer-default-export */
/** @format */
import { createTheme } from '@mui/material/';
import { darken, lighten } from '@mui/system';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import { Property } from 'csstype';
import * as mxlDesignVariables from './tokens/tokens.modernxl';

//Function to calculate the gradient angle for the slider component - written by Modern XL team
const rotateGradient = (linearGradient: string) => {
  // Note: matrix manipulation and scaling of the background color was moving
  // the mui slider thumb. Prefer a custom approach to modify the gradient angle
  // to match the design requirements.

  // Find original angle, i.e., characters between and including ( and d
  const regex = /\((.*?)d/;
  let newGradient = linearGradient; // Initialize
  const match = regex.exec(linearGradient);
  if (match) {
    // Convert to number and perform the rotation
    const newAngle = 360 - +match[1];
    newGradient = newGradient.replace(regex, `(${newAngle}d`);
  }

  return newGradient;
};

//Base font size set in pixels - this value is used to calculate rem units for fontSize
export const baseFontSize = 16;

export const modernxlLightTheme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 540,
      sm: 872,
      md: 1024,
      lg: 1440,
      xl: 1600,
    },
  },
  palette: {
    mode: 'light',
    common: {
      black: mxlDesignVariables.TOKEN_COLOR_THEME_BLACK,
      white: mxlDesignVariables.TOKEN_COLOR_THEME_WHITE,
    },
    primary: {
      main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_PRIMARY,
      disabled: lighten(
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_PRIMARY,
        0.38
      ),
      contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_PRIMARY,
      container:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_PRIMARY_CONTAINER,
      containerText:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_PRIMARY_CONTAINER,
      hover:
        mxlDesignVariables.TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_PRIMARY_OPACITY_0_08,
      onSurface: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_PRIMARY_ON_SURFACE,
    },
    secondary: {
      main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SECONDARY,
      disabled: lighten(
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SECONDARY,
        0.38
      ),
      contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SECONDARY,
      container:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SECONDARY_CONTAINER,
      containerText:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SECONDARY_CONTAINER,
      hover:
        mxlDesignVariables.TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_SECONDARY_OPACITY_0_08,
      onSurface: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SECONDARY_ON_SURFACE,
    },
    tertiary: {
      main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_TERTIARY,
      disabled: lighten(
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_TERTIARY,
        0.38
      ),
      contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_TERTIARY,
      container:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_TERTIARY_CONTAINER,
      containerText:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_TERTIARY_CONTAINER,
      hover:
        mxlDesignVariables.TOKEN_COLOR_THEME_STATE_LAYERS_LIGHT_TERTIARY_OPACITY_0_08,
      onSurface: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_TERTIARY_ON_SURFACE,
    },
    error: {
      main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ERROR,
      disabled: lighten(
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ERROR,
        0.38
      ),
      contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_ERROR,
      container: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ERROR_CONTAINER,
      containerText:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_ERROR_CONTAINER,
      onSurface: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ERROR_ON_SURFACE,
    },
    warning: {
      main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_WARNING,
      disabled: lighten(
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_WARNING,
        0.38
      ),
      contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_WARNING,
      container:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_WARNING_CONTAINER,
      containerText:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_WARNING_CONTAINER,
      onSurface: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_WARNING_ON_SURFACE,
    },
    info: {
      main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INFO,
      disabled: lighten(
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INFO,
        0.38
      ),
      contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_INFO,
      container: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INFO_CONTAINER,
      containerText:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_INFO_CONTAINER,
      onSurface: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INFO_ON_SURFACE,
    },
    success: {
      main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SUCCESS,
      disabled: lighten(
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SUCCESS,
        0.38
      ),
      contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SUCCESS,
      container:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SUCCESS_CONTAINER,
      containerText:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SUCCESS_CONTAINER,
      onSurface: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SUCCESS_ON_SURFACE,
    },
    safety: {
      main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SAFETY,
      light: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SAFETY_CONTAINER,
      disabled: lighten(
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SAFETY,
        0.38
      ),
      contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SAFETY,
      container:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SAFETY_CONTAINER,
      containerText:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SAFETY_CONTAINER,
      onSurface: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SAFETY_ON_SURFACE,
    },
    base1: {
      main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SURFACE,
      contrastText: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SURFACE,
    },
    base2: {
      main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_SURFACE_VARIANT,
      contrastText:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_SURFACE_VARIANT,
    },
    grey: {
      50: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL0,
      100: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL10,
      200: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL20,
      300: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL30,
      400: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL40,
      500: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL50,
      600: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL60,
      700: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL70,
      800: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL80,
      900: mxlDesignVariables.TOKEN_COLOR_THEME_REF_NEUTRAL_NEUTRAL90,
    },
    transparent: {
      transparent0: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_0,
      transparent8: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_8,
      transparent12: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_12,
      transparent16: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_16,
      transparent24: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_24,
      transparent32: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_32,
      transparent40: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_40,
      transparent48: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_48,
      transparent56: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_56,
      transparent64: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_64,
      transparent72: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_72,
      transparent80: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_80,
      transparent88: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_88,
      transparent96: mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_DARK_96,
      transparentInverse0:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_0,
      transparentInverse8:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_8,
      transparentInverse12:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_12,
      transparentInverse16:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_16,
      transparentInverse24:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_24,
      transparentInverse32:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_32,
      transparentInverse40:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_40,
      transparentInverse48:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_48,
      transparentInverse56:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_56,
      transparentInverse64:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_64,
      transparentInverse72:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_72,
      transparentInverse80:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_80,
      transparentInverse88:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_88,
      transparentInverse96:
        mxlDesignVariables.TOKEN_COLOR_THEME_TRANSPARENT_LIGHT_96,
    },
    text: {
      primary: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      primaryDisabled: lighten(
        mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
        0.38
      ),
      secondary: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_MUTED,
      secondaryDisabled: lighten(
        mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_MUTED,
        0.38
      ),
    },
    action: {
      active:
        mxlDesignVariables.TOKEN_COLOR_THEME_SURFACES_LIGHT_SURFACE_ACTIVE,
      hover: mxlDesignVariables.TOKEN_COLOR_THEME_SURFACES_LIGHT_SURFACE_HOVER,
      focus: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_FOCUS_A11Y,
    },
    gradient: {
      master: `${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_LIGHT_GRADIENT_MASTER
        .gradientType
        }-gradient(90deg, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_LIGHT_GRADIENT_MASTER
          .stops[0].color
        } ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_LIGHT_GRADIENT_MASTER
          .stops[0].position * 100
        }%, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_LIGHT_GRADIENT_MASTER
          .stops[1].color
        } ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_LIGHT_GRADIENT_MASTER
          .stops[1].position * 100
        }%)`,
      primary: `${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_LIGHT
        .gradientType
        }-gradient(90deg, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_LIGHT
          .stops[0].color
        } ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_LIGHT
          .stops[0].position * 100
        }%, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_LIGHT
          .stops[1].color
        } ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PRIMARY_LIGHT
          .stops[1].position * 100
        }%)`,
      secondary: `${mxlDesignVariables
        .TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_1.gradientType
        }-gradient(90deg, ${mxlDesignVariables
          .TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_1.stops[0]
          .color
        } ${mxlDesignVariables
          .TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_1.stops[0]
          .position * 100
        }%, ${mxlDesignVariables
          .TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_1.stops[1]
          .color
        } ${mxlDesignVariables
          .TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_1.stops[1]
          .position * 100
        }%), ${mxlDesignVariables
          .TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_0.gradientType
        }-gradient(117.32deg, ${mxlDesignVariables
          .TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_0.stops[0]
          .color
        } ${mxlDesignVariables
          .TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_0.stops[0]
          .position * 100
        }%, ${mxlDesignVariables
          .TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_0.stops[1]
          .color
        } ${mxlDesignVariables
          .TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_SECONDARY_LIGHT_0.stops[1]
          .position * 100
        }%)`,
      tertiary: `${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_LIGHT
        .gradientType
        }-gradient(137.13deg, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_LIGHT
          .stops[0].color
        } ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_LIGHT
          .stops[0].position * 100
        }%, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_LIGHT
          .stops[1].color
        } ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_TERTIARY_LIGHT
          .stops[1].position * 100
        }%)`,
      pendo: `${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PENDO_GRADIENT_LIGHT
        .gradientType
        }-gradient(90deg, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PENDO_GRADIENT_LIGHT
          .stops[0].color
        } ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PENDO_GRADIENT_LIGHT
          .stops[0].position * 100
        }%, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PENDO_GRADIENT_LIGHT
          .stops[1].color
        } ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_PENDO_GRADIENT_LIGHT
          .stops[1].position * 100
        }%)`
    },
    blur: {
      primary: `blur(20px)`,
    },
    inverse: {
      main: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_SURFACE,
      contrastText:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_ON_SURFACE,
      primary: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_PRIMARY,
      secondary:
        mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_SECONDARY,
      tertiary: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_INVERSE_TERTIARY,
    },
    backgroundNoGradient:
      mxlDesignVariables.TOKEN_COLOR_THEME_REF_TERTIARY_TERTIARY98,

    divider: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_OUTLINE_VARIANT,
    dividerLight: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_OUTLINE,
    defaultBackground: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_BACKGROUND,
    onDefaultBackground: mxlDesignVariables.TOKEN_COLOR_THEME_SYS_LIGHT_ON_BACKGROUND
  },
  spacing: 4,
  typography: {
    htmlFontSize: baseFontSize,
    fontFamily: `"Inter", Helvetica, sans-serif`,
    letterSpacing:
      mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.letterSpacing,
    lineHeight:
      mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.lineHeight /
      mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.fontSize,
    fontWeightLight: 300,
    fontWeightRegular:
      mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.fontWeight,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H1.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H1.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H1.letterSpacing,
    },
    h2: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H2.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H2.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H2.letterSpacing,
    },
    h3: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H3.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H3.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_HEADLINE_H3.letterSpacing,
    },
    subtitle1: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.letterSpacing,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.fontSize,
    },
    subtitle2: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_2.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_2.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_2.letterSpacing,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_2.lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_2.fontSize,
    },
    subtitle3: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_3.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_3.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_3.letterSpacing,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_3.lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_3.fontSize,
    },
    subtitle4: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBHEAD_4.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBHEAD_4.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBHEAD_4.letterSpacing,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBHEAD_4.lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBHEAD_4.fontSize,
    },
    bodyLarge: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_LARGE.fontSize / baseFontSize}rem`,
      fontWeight: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_LARGE.fontWeight,
      lineHeight: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_LARGE.lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_LARGE.fontSize,
      textTransform: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_LARGE
        .textCase as Property.TextTransform,
    },
    body1: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.letterSpacing,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM.fontSize,
      textTransform: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM
        .textCase as Property.TextTransform,
    },
    body2: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.letterSpacing,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.fontSize,
      textTransform: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL
        .textCase as Property.TextTransform,
    },
    body3: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS
          .fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS
          .letterSpacing,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS
          .lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS
          .fontSize,
      textTransform: mxlDesignVariables
        .TOKEN_FONT_TYPOGRAPHY_BODY_BODY_MEDIUM_EMPHASIS
        .textCase as Property.TextTransform,
    },
    label1: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_MUTED,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL
          .letterSpacing,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL.lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL.fontSize,
      textTransform: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABEL_SMALL
        .textCase as Property.TextTransform,
    },
    label2: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_MUTED,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM
          .letterSpacing,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM.lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM.fontSize,
      textTransform: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_MEDIUM
        .textCase as Property.TextTransform,
    },
    label3: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_MUTED,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE.fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE
          .letterSpacing,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE.lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE.fontSize,
      textTransform: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_LABLE_LARGE
        .textCase as Property.TextTransform,
    },
    caption: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS.fontSize / baseFontSize}rem`,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS
          .fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS
          .letterSpacing,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS
          .lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS
          .fontSize,
      textTransform: mxlDesignVariables
        .TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL_EMPHASIS
        .textCase as Property.TextTransform,
    },
    overline: {
      color: mxlDesignVariables.TOKEN_COLOR_THEME_TEXT_LIGHTTHEME_DEFAULT,
      fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT.fontSize / baseFontSize}rem`,
      textDecoration:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
          .textDecoration,
      fontWeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
          .fontWeight,
      letterSpacing:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
          .letterSpacing,
      fontStyle:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
          .fontStyle,
      lineHeight:
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
          .lineHeight /
        mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
          .fontSize,
      textTransform: mxlDesignVariables
        .TOKEN_FONT_TYPOGRAPHY_LABEL_OVERLINE_DEFAULT
        .textCase as Property.TextTransform,
    },
  },
  shadowsCustom: {
    main: `${mxlDesignVariables.TOKEN_EFFECT_DROP_SHADOW.offsetX}px ${mxlDesignVariables.TOKEN_EFFECT_DROP_SHADOW.offsetY}px ${mxlDesignVariables.TOKEN_EFFECT_DROP_SHADOW.radius}px ${mxlDesignVariables.TOKEN_EFFECT_DROP_SHADOW.spread}px ${mxlDesignVariables.TOKEN_EFFECT_DROP_SHADOW.color}`,
    hover: `${mxlDesignVariables.TOKEN_EFFECT_HOVER_SHADOW.offsetX}px ${mxlDesignVariables.TOKEN_EFFECT_HOVER_SHADOW.offsetY}px ${mxlDesignVariables.TOKEN_EFFECT_HOVER_SHADOW.radius}px ${mxlDesignVariables.TOKEN_EFFECT_HOVER_SHADOW.spread}px ${mxlDesignVariables.TOKEN_EFFECT_HOVER_SHADOW.color}`,
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: theme.shadowsCustom.main,
          background: theme.palette.gradient.master,
          backdropFilter: theme.palette.blur.primary,
          borderRadius: '8px',
          '&.MuiPaper-root': {
            borderRadius: '8px',
          },
          '&:before': {
            display: 'none',
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          padding: `0 ${36 / baseFontSize}rem 0 0`,
          '& .MuiAccordionSummary-content': {
            margin: `${24 / baseFontSize}rem 0 ${24 / baseFontSize}rem ${36 / baseFontSize}rem`,
            flexDirection: 'column',

            '&.Mui-expanded': {
              margin: `${24 / baseFontSize}rem 0 ${24 / baseFontSize}rem ${36 / baseFontSize}rem`,
            },

            '& .MuiTypography-body1': {
              color: theme.palette.text.secondary
            }
          },
          '&.Mui-expanded': {
            background: theme.palette.gradient.secondary,
            '& .MuiTypography-root': {
              color: theme.palette.common.white,
            },
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            '&.Mui-expanded': {
              '& .MuiSvgIcon-root': {
                color: ''.concat(
                  theme.palette.transparent.transparentInverse96,
                  ' !important'
                ),
              },
            },
          },
        })
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 8,
          '& .MuiAlert-action': {
            padding: `${2 / baseFontSize}rem 0 0 ${16 / baseFontSize}rem`,
          },
          '& .MuiAlert-message': {
            color: theme.palette.text.primary,
          },
          '& .MuiIconButton-root': {
            color: theme.palette.text.primary,
          },
        }),
        filledError: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.error.container,
        }),
        filledInfo: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.info.container,
        }),
        filledSuccess: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.success.container,
        }),
        filledWarning: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.warning.container,
        }),
        standardError: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.action.active,
        }),
        standardInfo: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.action.active,
        }),
        standardSuccess: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.action.active,
        }),
        standardWarning: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.action.active,
        }),

      },
    },
    MuiAutocomplete: {
      defaultProps: {},
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiOutlinedInput-root': {
            padding: `${1 / baseFontSize}rem ${9 / baseFontSize}rem !important`,
            height: 'auto',
          },
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS.fontSize / baseFontSize}rem`,
          textDecoration:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
              .textDecoration,
          fontWeight:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
              .fontWeight,
          fontStyle:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
              .fontStyle,
          fontStretch:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
              .fontStretch,
          letterSpacing:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
              .letterSpacing,
          lineHeight:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
              .lineHeight /
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_AVATAR_INITIALS
              .fontSize,
          '& .MuiSvgIcon-root': {
            color: 'inherit'
          }
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        itemsBeforeCollapse: 0,
        maxItems: 4,
      },
      styleOverrides: {
        root: {
          '& .MuiLink-root': {
            fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BODY_BODY_SMALL.fontSize / baseFontSize}rem`,
            display: 'flex',
            alignItems: 'center',
            gap: 8,

            svg: {
              fontSize: `${16 / baseFontSize}rem`,
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            ...(ownerState.color === 'primary' && {
              color: theme.palette.primary.main,
            }),
            ...(ownerState.color === 'secondary' && {
              color: theme.palette.secondary.main,
            }),
            ...(ownerState.color === 'tertiary' && {
              color: theme.palette.tertiary.main,
            }),
            ...(ownerState.color === 'warning' && {
              color: theme.palette.warning.main,
            }),
            ...(ownerState.color === 'info' && {
              color: theme.palette.info.main,
            }),
            ...(ownerState.color === 'error' && {
              color: theme.palette.error.main,
            }),
            ...(ownerState.color === 'safety' && {
              color: theme.palette.safety.main,
            }),
            ...(ownerState.color === 'inherit' && {
              color: theme.palette.text.secondary,
            }),
            ...(ownerState.color === undefined && {
              color: theme.palette.text.secondary,
            })

          }
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const modifyColor = theme.palette.mode === 'light' ? darken : lighten;

          const colorMap = {
            inherit: theme.palette.primary.main,
            primary: theme.palette.primary.main,
            secondary: theme.palette.secondary.main,
            tertiary: theme.palette.tertiary.main,
            warning: theme.palette.warning.main,
            info: theme.palette.info.main,
            success: theme.palette.success.main,
            error: theme.palette.error.main,
          };

          return {
            borderRadius: '2rem',
            ...(ownerState.variant === 'outlined' && {
              border: `2px solid ${theme.palette.text.primary}`,
              color: theme.palette.text.primary,
            }),
            ...(ownerState.variant === 'contained' && {
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: colorMap[ownerState.color || 'primary']
            }),
            '&:hover': {
              boxShadow: 'none',
              ...(ownerState.variant === 'contained' && {
                backgroundColor: modifyColor(
                  colorMap[ownerState.color || 'primary'],
                  0.08
                ),
              }),
              ...(ownerState.variant === 'outlined' && {
                border: `2px solid ${theme.palette.text.primary}`,
                backgroundColor: theme.palette.transparent.transparent8,
              }),
            },
            '&:focus-visible': {
              outline: `2px solid ${theme.palette.action.active}`,
              outlineOffset: `-4px`,
              borderWidth: '2px !important',
              borderColor: theme.palette.action.focus,
              boxShadow: 'none',
              ...(ownerState.variant === 'contained' && {
                backgroundColor: modifyColor(
                  colorMap[ownerState.color || 'primary'],
                  0.12
                ),
              }),
              ...(ownerState.variant === 'outlined' && {
                backgroundColor: theme.palette.transparent.transparent12,
              }),
            },
            '&:active': {
              outline: `2px solid ${theme.palette.action.active}`,
              outlineOffset: `-4px`,
              borderColor: theme.palette.action.focus,
              ...(ownerState.variant === 'contained' && {
                backgroundColor: modifyColor(
                  colorMap[ownerState.color || 'primary'],
                  0.16
                ),
              }),
              ...(ownerState.variant === 'outlined' && {
                backgroundColor: theme.palette.transparent.transparent16,
                outline: 'none',
              }),
            },
            '&.MuiButton-text': {
              '&:hover': {
                backgroundColor: theme.palette.transparent.transparent8,
                outline: 'none',
              },
              '&:focus-visible': {
                backgroundColor: theme.palette.transparent.transparent12,
              },
              '&:active': {
                backgroundColor: theme.palette.transparent.transparent16,
                outline: 'none',
              },
            },
            '&.Mui-disabled': {
              backgroundColor: theme.palette.transparent.transparent16,
              color: theme.palette.transparent.transparent40,
              borderColor: theme.palette.transparent.transparent0
            },
            '&[aria-disabled=true]': {
              opacity: '0.8',
              color: theme.palette.transparent.transparent40,
              borderColor: theme.palette.transparent.transparent0,
              pointerEvents: 'none'
            },
            ...(ownerState.size === 'large' && {
              padding: `${12 / baseFontSize}rem ${34 / baseFontSize}rem`,
              fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE.fontSize / baseFontSize}rem`,
              fontWeight:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE
                  .fontWeight,
              letterSpacing:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE
                  .letterSpacing,
              textTransform: mxlDesignVariables
                .TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE
                .textCase as Property.TextTransform,
              textDecoration:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE
                  .textDecoration,
              fontStyle:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE
                  .fontStyle,
              fontStretch:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE
                  .fontStretch,
              lineHeight:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE
                  .lineHeight /
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_LARGE
                  .fontSize,
            }),
            ...(ownerState.size === 'medium' && {
              padding: `${10 / baseFontSize}rem ${24 / baseFontSize}rem`,
              fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM.fontSize / baseFontSize}rem`,
              textDecoration:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM
                  .textDecoration,
              fontWeight:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM
                  .fontWeight,
              fontStyle:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM
                  .fontStyle,
              fontStretch:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM
                  .fontStretch,
              letterSpacing:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM
                  .letterSpacing,
              textTransform: mxlDesignVariables
                .TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM
                .textCase as Property.TextTransform,
              lineHeight:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM
                  .lineHeight /
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_MEDIUM
                  .fontSize,
            }),
            ...(ownerState.size === 'small' && {
              padding: `${6 / baseFontSize}rem ${16 / baseFontSize}rem`,
              fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL.fontSize / baseFontSize}rem`,
              textDecoration:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
                  .textDecoration,
              fontWeight:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
                  .fontWeight,
              fontStyle:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
                  .fontStyle,
              fontStretch:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
                  .fontStretch,
              letterSpacing:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
                  .letterSpacing,
              textTransform: mxlDesignVariables
                .TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
                .textCase as Property.TextTransform,
              lineHeight:
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
                  .lineHeight /
                mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_BUTTON_BUTTON_SMALL
                  .fontSize,
            }),
            ...(ownerState.variant === 'text' && {
              '&.Mui-disabled': {
                backgroundColor: 'transparent'
              },
              '&:hover': {
                boxShadow: 'none',
              },
            }),
            '& .MuiSvgIcon-root': {
              color: 'inherit'
            }
          };
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            borderRadius: 4,
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.gradient.master,
          '&:hover': {
            background: theme.palette.action.hover,
          },
          '&.active': {
            background: theme.palette.action.active,
          },
        }),
      },
    },
    MuiCardActionArea: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: baseFontSize,
        },
        body: {
          /*background: `linear-gradient(0, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_1
            }, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_1
            }), ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_0
              .gradientType
            }-gradient(135deg, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_0
              .stops[0].color
            } ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_0
              .stops[0].position * 100
            }%, ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_0
              .stops[1].color
            } ${mxlDesignVariables.TOKEN_GRADIENT_THEME_SURFACES_GRADIENT_BG_LIGHT_0
              .stops[1].position * 100
            }%)`,
          backgroundAttachment: 'fixed',*/
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme: { palette }, checked }) => ({
          '&:hover': {
            backgroundColor: palette.transparent.transparent8,
          },
          '&:focus-within': {
            backgroundColor: palette.transparent.transparent16,
          },
          '&.Mui-disabled': {
            '& .MuiSvgIcon-root': {
              fill: palette.transparent.transparent40,
              backgroundColor: palette.transparent.transparentInverse40,
            },
          },
          '& .MuiSvgIcon-root': {
            fill: checked ? palette.secondary.main : palette.text.secondary,
            color: palette.secondary.contrastText,
            fontSize: `${24 / baseFontSize}rem`,
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP.fontSize / baseFontSize}rem`,
          letterSpacing:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .letterSpacing,
          fontWeight:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .fontWeight,
          fontStyle:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .fontStyle,
          lineHeight:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .lineHeight /
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .fontSize,
          textDecoration:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .textDecoration,
          textTransform: mxlDesignVariables
            .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
            .textCase as Property.TextTransform,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          boxShadow: theme.shadowsCustom.main,
          borderRadius: 16,
          padding: `${24 / baseFontSize}rem`,
          background: theme.palette.action.active
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.fontSize / baseFontSize}rem`,
          fontWeight: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.fontWeight,
          lineHeight: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.lineHeight / mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_TITLE_SUBTITLE_1.fontSize,
          alignItems: 'center',
          display: 'flex',
          gap: 8,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          display: 'flex',
          gap: 4,
          '& .MuiSvgIcon-root': {
            color: 'inherit'
          }
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          '& [class*=MuiFormHelperText-root]': {
            marginLeft: `0 !important`,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          borderRadius: 24,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP.fontSize / baseFontSize}rem`,
          textDecoration:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .textDecoration,
          fontWeight:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .fontWeight,
          fontStyle:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .fontStyle,
          fontStretch:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .fontStretch,
          letterSpacing:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .letterSpacing,
          lineHeight:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .lineHeight /
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .fontSize,
          textTransform: mxlDesignVariables
            .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
            .textCase as Property.TextTransform,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
          '&:focus-visible': {
            backgroundColor: theme.palette.transparent.transparent16,
          },
          '&:hover': {
            backgroundColor: theme.palette.transparent.transparent8,
          },
          '&.Mui-disabled': {
            color: theme.palette.transparent.transparent40,
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme: { palette } }) => ({
          fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT.fontSize / baseFontSize}rem`,
          textDecoration:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
              .textDecoration,
          fontWeight:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
              .fontWeight,
          fontStyle:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
              .fontStyle,
          fontStretch:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
              .fontStretch,
          letterSpacing:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
              .letterSpacing,
          lineHeight:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
              .lineHeight /
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT.fontSize,
          height: '2.5rem',
          '& legend': {
            display: 'none',
          },
          '& [class*=MuiOutlinedInput-notchedOutline]': {
            top: 0,
          },
          '&.Mui-disabled': {
            '.MuiSelect-select': {
              '-webkit-text-fill-color': palette.transparent.transparent40,
            },
          },
        }),
        sizeSmall: {
          height: '2rem',
        },
        sizeLarge: {
          height: '3rem',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
      },
      styleOverrides: {
        root: {
          position: 'static',
          transform: 'none',
          padding: `${6 / baseFontSize}rem 0`,
          fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP.fontSize / baseFontSize}rem`,
          letterSpacing:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .letterSpacing,
          fontWeight:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .fontWeight,
          fontStyle:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .fontStyle,
          lineHeight:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .lineHeight /
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .fontSize,
          textDecoration:
            mxlDesignVariables
              .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
              .textDecoration,
          textTransform: mxlDesignVariables
            .TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_LABEL_HELPER_TEXT_CHIP
            .textCase as Property.TextTransform,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        color: 'inherit',
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiPaper-root': {
            borderRadius: '8px',
            background: theme.palette.defaultBackground
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `${6 / baseFontSize}rem ${12 / baseFontSize}rem`,

          '&.MuiMenuItem-root.Mui-selected': {
            backgroundColor: theme.palette.base1.main,
          },
          '&:hover, &.MuiMenuItem-root.Mui-selected:hover': {
            backgroundColor: theme.palette.secondary.container,
            '& .MuiSvgIcon-root': {
              fill: theme.palette.secondary.contrastText,
            },
            '& .MuiTypography-root': {
              color: theme.palette.secondary.containerText,
            },
          },
        }),
      },
    },
    MuiPagination: {
      defaultProps: {
        color: 'standard',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.base1.main,
        }),
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            borderRadius: '20px',
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme: { palette } }) => ({
          '&:hover': {
            backgroundColor: palette.transparent.transparent8,
          },
          '&:focus-within': {
            backgroundColor: palette.transparent.transparent16,
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme: { palette } }) => ({
          '&.Mui-disabled': {
            border: `1px solid ${palette.transparent.transparent40}`,
            backgroundColor: palette.transparent.transparent16,
            'fieldset.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
          '& .MuiSvgIcon-root': {
            color: 'inherit',
            '&.Mui-disabled': {
              fill: palette.transparent.transparent40,
            },
          }
        }),
        select: {
          fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT.fontSize / baseFontSize}rem`,
          fontWeight:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
              .fontWeight,
          fontStyle:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
              .fontStyle,
          letterSpacing:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_INPUT_TEXT
              .letterSpacing,
        },
        sizeSmall: {
          height: '2rem',
        },
        sizeLarge: {
          height: '3rem',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: `${7 / baseFontSize}rem ${5 / baseFontSize}rem`,
        },
        switchBase: ({ theme }) => ({
          padding: `${10 / baseFontSize}rem ${12 / baseFontSize}rem ${10 / baseFontSize}rem ${8 / baseFontSize}rem`,
          '&.Mui-checked ': {
            padding: `${10 / baseFontSize}rem ${8 / baseFontSize}rem ${10 / baseFontSize}rem ${12 / baseFontSize}rem`,
          },
          '& > span.MuiSwitch-thumb': {
            backgroundColor: theme.palette.base2.main,
          },
          '&.Mui-checked > span.MuiSwitch-thumb': {
            backgroundColor: theme.palette.secondary.contrastText,
          },
          '& + span.MuiSwitch-track': {
            border: `1px solid ${theme.palette.transparent.transparent0} !important`,
            backgroundColor: `${theme.palette.transparent.transparent48} !important`,
          },
          '&.Mui-checked + span.MuiSwitch-track': {
            border: `1px solid ${theme.palette.secondary.main} !important`,
            backgroundColor: `${theme.palette.secondary.main} !important`,
          },
        }),
        thumb: ({ theme }) => ({
          width: 18,
          height: 18,
          boxShadow: 'none',
        }),
        track: ({ theme }) => ({
          borderRadius: 14,
          opacity: '1 !important',
          backgroundColor: `${theme.palette.secondary.main} !important`,
        }),
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          borderRadius: 4,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          '&:focus-visible': {
            backgroundColor: theme.palette.transparent.transparent16,
          },
          '&.Mui-selected': {
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          height: 4,
          backgroundColor: theme.palette.primary.main,
        }),
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID.fontSize / baseFontSize}rem`,
          textDecoration:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
              .textDecoration,
          fontWeight:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
              .fontWeight,
          fontStyle:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
              .fontStyle,
          fontStretch:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
              .fontStretch,
          letterSpacing:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
              .letterSpacing,
          lineHeight:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
              .lineHeight /
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
              .fontSize,
          textTransform: mxlDesignVariables
            .TOKEN_FONT_TYPOGRAPHY_INPUTS_TABLE_HEADER_ID
            .textCase as Property.TextTransform,
        },
      },
    },
    MuiTextField: {
      defaultProps: {},
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '4px',
          color: theme.palette.text.secondary,
          '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.action.active,
            color: theme.palette.text.secondary,
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.secondary.main,
            },
          },
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          borderRadius: 8,
          backgroundColor: theme.palette.onDefaultBackground,
          fontSize: `${mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP.fontSize / baseFontSize}rem`,
          textDecoration:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP
              .textDecoration,
          fontWeight:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP.fontWeight,
          fontStyle:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP.fontStyle,
          fontStretch:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP.fontStretch,
          letterSpacing:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP
              .letterSpacing,
          lineHeight:
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP.lineHeight /
            mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP.fontSize,
          textTransform: mxlDesignVariables.TOKEN_FONT_TYPOGRAPHY_INPUTS_TOOLTIP
            .textCase as Property.TextTransform,
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.onDefaultBackground,
        }),
      },
    },
    MuiSlider: {
      defaultProps: {

      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: 'secondary',
          '& .MuiSlider-thumb': {
            backgroundImage: rotateGradient(theme.palette.gradient.tertiary),
            '&:focus, &:hover, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 8px ${theme.palette.tertiary.hover}`
            },
            '&.Mui-active': {
              boxShadow: `0px 0px 0px 14px ${theme.palette.tertiary.hover}`
            }
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: theme.palette.tertiary.main,
            color: theme.palette.tertiary.contrastText,
            borderRadius: '50%',
            width: '1.75rem',
            height: '1.75rem',
            fontWeight: 500,
            '&:before': {
              width: '0.75rem',
              height: '0.75rem',
              bottom: '2px'
            }
          }
        })
      }
    }
  },
});
