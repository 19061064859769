/** @format */

import Difficulty1 from '../Icons/Difficulty1';
import Difficulty2 from '../Icons/Difficulty2';
import Difficulty3 from '../Icons/Difficulty3';
import Difficulty4 from '../Icons/Difficulty4';

function DifficultyTip(props) {
	return (
		<div>
			<span className='label'>Difficulty Level</span>
			<span className='item-value'>
				{props.difficulty === '1'
					? 'Easy'
					: props.difficulty === '2'
					? 'Moderate'
					: props.difficulty === '3'
					? 'Hard'
					: 'Hardest'}
			</span>

			<span className='label'>Correct on first try</span>
			<span className='item-value'>
				{props.difficulty === '1'
					? '97.8%'
					: props.difficulty === '2'
					? '83.2%'
					: props.difficulty === '3'
					? '75.4%'
					: '67.2%'}
			</span>
		</div>
	);
}

function DifficultyLevel(props) {
	return (
		<div className='difficulty'>
			{props.difficulty === '1' ? (
				<>
					<svg
						width='44'
						height='8'
						viewBox='0 0 44 8'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<circle
							cx='4'
							cy='4'
							r='4'
							className='green'
						/>
						<circle
							cx='16'
							cy='4'
							r='4'
							fill='currentColor'
						/>
						<circle
							cx='28'
							cy='4'
							r='4'
							fill='currentColor'
						/>
						<circle
							cx='40'
							cy='4'
							r='4'
							fill='currentColor'
						/>
					</svg>
					<div>Easy</div>
				</>
			) : props.difficulty === '2' ? (
				<>
					<svg
						width='44'
						height='8'
						viewBox='0 0 44 8'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<circle
							cx='4'
							cy='4'
							r='4'
							className='yellow'
						/>
						<circle
							cx='16'
							cy='4'
							r='4'
							className='yellow'
						/>
						<circle
							cx='28'
							cy='4'
							r='4'
							fill='currentColor'
						/>
						<circle
							cx='40'
							cy='4'
							r='4'
							fill='currentColor'
						/>
					</svg>

					<div>Moderate</div>
				</>
			) : props.difficulty === '3' ? (
				<>
					<svg
						width='44'
						height='8'
						viewBox='0 0 44 8'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<circle
							cx='4'
							cy='4'
							r='4'
							className='red'
						/>
						<circle
							cx='16'
							cy='4'
							r='4'
							className='red'
						/>
						<circle
							cx='28'
							cy='4'
							r='4'
							className='red'
						/>
						<circle
							cx='40'
							cy='4'
							r='4'
							fill='currentColor'
						/>
					</svg>

					<div>Hard</div>
				</>
			) : props.difficulty === '4' ? (
				<>
					<svg
						width='44'
						height='8'
						viewBox='0 0 44 8'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<circle
							cx='4'
							cy='4'
							r='4'
							className='blue'
						/>
						<circle
							cx='16'
							cy='4'
							r='4'
							className='blue'
						/>
						<circle
							cx='28'
							cy='4'
							r='4'
							className='blue'
						/>
						<circle
							cx='40'
							cy='4'
							r='4'
							fill='currentColor'
						/>
					</svg>

					<div>Hardest</div>
				</>
			) : null}
		</div>
	);
}

export default DifficultyLevel;
